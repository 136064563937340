import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Async } from 'react-select';
import debounce from 'es6-promise-debounce';
import qs from 'querystring';
import { Alert, Button, Container, Row, Col } from 'reactstrap';
import {
    cyderLoginAction,
    cyderLoginSetErrorMessageAction,
    cyderLoginClearErrorMessageAction,
    cyderLoginResetState,
    cyderResetProfileAction,
    loginPageGetCompaniesAction,
} from '../../actions/security/cyderSecurityAction';
import LoadingSpinner from '../../cyder/loadingSpinner/LoadingSpinner';
import history from '../../history';
import './Login.css';
import CyderForm from '../../cyder/forms/CyderForm';
import Cyder4080Panel from '../../cyder/panels/Cyder4080Panel';
import CyderInputGroup from '../../cyder/forms/CyderInputGroup';
import FrontLogo from '../../cyder/media/FrontLogo';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.staffLoginFunc = this.staffLoginFunc.bind(this);
        this.getOptionsApprovers = this.getOptionsApprovers.bind(this);
        this.validateThenSubmit = this.validateThenSubmit.bind(this);
        this.changeCompanySelectedState = this.changeCompanySelectedState.bind(this);
        this.changeButtonClickedState = this.changeButtonClickedState.bind(this);
        this.state = {
            buttonClicked: false,
            companySelected: false,
            companySelectedValue: '',
            isStaff: false,
        };
    }
    componentDidMount() {
        this.props.resetLoginPageState();
    }
    changeButtonClickedState() {
        this.setState({
            buttonClicked: !this.state.buttonClicked,
        });
    }
    changeCompanySelectedState(val) {
        this.setState({
            companySelected: true,
            companySelectedValue: val,
        });
    }
    staffLoginFunc() {
        this.setState({
            isStaff: true,
        });
    }
    getOptionsApprovers(input) {
        return this.props.getCompanyList(input);
    }
    render() {
        const descDiv = (
            <div>
                <p>Your portal to Member’s Benefits and exclusive green building information</p>
                <Link to="/">Homepage</Link>
            </div>
        );
        return (
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="login-panel">
                            <Cyder4080Panel
                                className="semi-transparent"
                                child-position="left"
                                title="Welcome to SGBC Online"
                                description={descDiv}
                            >
                                <FrontLogo />
                            </Cyder4080Panel>
                            <br />
                            {!this.state.buttonClicked ? (
                                <CyderForm className="semi-transparent">
                                    <h6 className="text-center">Do you have a log in ID?</h6>
                                    <br />
                                    <div className="d-flex justify-content-around">
                                        <Button color="success" onClick={this.changeButtonClickedState}>
                                            Yes, log in now
                                        </Button>
                                        <Link to="/createaccount">
                                            <Button color="primary">Create account</Button>
                                        </Link>
                                    </div>
                                </CyderForm>
                            ) : this.props.requesting === true ? (
                                <LoadingSpinner />
                            ) : (
                                <ActualLoginComponent
                                    isStaff={this.state.isStaff}
                                    changeButtonClickedState={this.changeButtonClickedState}
                                    changeCompanySelectedState={this.changeCompanySelectedState}
                                    companySelectedValue={this.state.companySelectedValue}
                                    companySelected={this.state.companySelected}
                                    validateThenSubmit={this.validateThenSubmit}
                                    getOptionsApprovers={this.getOptionsApprovers}
                                    staffLoginFunc={this.staffLoginFunc}
                                    {...this.props}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    validateThenSubmit() {
        this.props.clearErrorMessage();

        const username = document.getElementById(this.props.fields[0].id).value;
        const password = document.getElementById(this.props.fields[1].id).value;

        if (username === '' || password === '') {
            this.props.setErroMessage('Please enter both username and password');
            // return false;
        }
        const redirect = qs.parse(this.props.location.search.substr(1)).redirect;
        this.props.loginSubmit(username, password, this.state.companySelectedValue.value, redirect);
    }
}

const ActualLoginComponent = props => {
    const style = props.loginError ? null : { display: 'none' };
    return (
        <CyderForm className="semi-transparent">
            <div className="form-group">
                <Alert style={style} color="danger">
                    {props.errorMessage ? props.errorMessage : null}
                </Alert>
                {props.failedLoginCount >= 3 ? (
                    <Alert style={style} color="success">
                        Is your organisation registered with SGBC but you do not have a log in account?
                        <Link to="/createaccount">
                            {' '}
                            <strong className="color-light">Click Here!</strong>
                        </Link>
                    </Alert>
                ) : null}

                {!props.isStaff ? (
                    <Async
                        className="mb-3"
                        placeholder="Search company..."
                        autoload={false}
                        value={props.companySelectedValue}
                        onChange={val => props.changeCompanySelectedState(val)}
                        name="company"
                        filterOptions={options => {
                            return options;
                        }}
                        loadOptions={debounce(props.getOptionsApprovers, 500)}
                    />
                ) : (
                    <h5>Login as SGBC Staff</h5>
                )}
                {props.companySelected || props.isStaff === true ? (
                    [<CyderInputGroup key={0} field={props.fields[0]} />, <CyderInputGroup key={1} field={props.fields[1]} />]
                ) : (
                    <div className="d-flex mt-3">
                        <div>
                            <Link to="/forgotusername">Forgot Username</Link>
                            {' | '}
                            <Link to="/forgotpassword">Forgot Password</Link>
                        </div>
                        <div className="ml-auto">
                            <Link onClick={props.staffLoginFunc} to="#">
                                SGBC Staff Login
                            </Link>
                            {' | '}
                            <Link onClick={props.changeButtonClickedState} to="#">
                                Back
                            </Link>
                        </div>
                    </div>
                )}
            </div>

            {props.companySelected || props.isStaff ? (
                <div className="align-middle">
                    <Button
                        color="default"
                        disabled={props.requesting}
                        className="btn btn-primary btn-rounded btn-outline"
                        type="submit"
                        onClick={() => props.validateThenSubmit()}
                    >
                        Submit
                    </Button>
                    <div className="float-right">
                        <Link to="/forgotusername">Forgot Username</Link>
                        {' | '}
                        <Link to="/forgotpassword">Forgot Password</Link>
                    </div>
                </div>
            ) : null}
        </CyderForm>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        fields: state.cyderLoginReducer.fields,
        loginError: state.cyderLoginReducer.loginError,
        errorMessage: state.cyderLoginReducer.errorMessage,
        failedLoginCount: state.cyderLoginReducer.failedLoginCount,
        requesting: state.cyderLoginReducer.requesting,
        user: state.profile.user,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        clearErrorMessage: () => {
            dispatch(cyderLoginClearErrorMessageAction());
        },
        setErroMessage: errorMessage => {
            dispatch(cyderLoginSetErrorMessageAction(errorMessage));
        },
        loginSubmit: (username, password, company_id, redirect) => {
            // Updated by Fernando, sending as Plaintext
            // which is fine, as the API is HTTPS, so the password is sent in secured connection.
            // The password checking is done at the backend
            //const hashedpw = sha512(password);

            const data = {
                username,
                password: password,
                organization: company_id,
            };

            var navigateChangePassword = () => {
                var navigate = '/changepassword';
                // history.push(navigate);
            };
            var navigateHome = is_staff => {
                var navigate = redirect !== undefined ? redirect : is_staff === 1 ? '/staff/home' : '/member/home';
                history.push(navigate);
            };

            dispatch(cyderLoginAction(data)).then(res => {
                if (res.errormessage) {
                    // error
                } else {
                    if (res.needchangepassword === 'Y' || res.firstlogin === 'Y') {
                        navigateChangePassword();
                    } else {
                        navigateHome(res.data.is_staff);
                    }
                }
            });
        },
        resetLoginPageState: () => {
            dispatch(cyderLoginResetState());
            dispatch(cyderResetProfileAction());
        },
        getCompanyList: input => {
            return dispatch(loginPageGetCompaniesAction(input));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
