// import EmptyView1 from '../../layouts/EmptyView1'
import React from 'react';
import { connect } from 'react-redux';

import {
    Alert,
    Button,
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    CardText,
    Form,
    FormGroup,
    FormText,
    Label,
    Input,
    Container,
} from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';

import { getCompanyInfo, getPersonInfo, updatePassword } from '../../actions/profile/action';

import '../../css/cyder/panel/standard-4080-panel.css';

const GenericPanel = props => {
    return (
        <Row className="d-flex justify-content-center">
            <div style={{ marginBottom: '20px', width: '100%' }}>
                {/* <div className="standard-4080-panel"> */}
                <CardTitle>{props.headerContent}</CardTitle>
                {props.children}
                {/* </div> */}
            </div>
        </Row>
    );
};
const Panel1Content = ({ username, email, officialRepName, officialRepEmail }) => {
    return (
        <Row>
            <Col md={12}>
                <strong>Account Username:</strong> {username}
                <br />
                <strong>Account Email:</strong> {email}
                <br />
                <strong>Official Management Rep:</strong> {officialRepName}
                <br />
                <strong>Official Management Rep's Email:</strong> {officialRepEmail}
                <br />
            </Col>
        </Row>
    );
};
const Panel3Content = ({ updatePassword, onPasswordChange, onConfirmPasswordChange, password, confirmPassword }) => {
    return (
        <CardBody>
            <Row>
                <Col md={7}>
                    <Form>
                        <FormGroup>
                            <Label>Password *</Label>
                            <Input name="password" type="password" onChange={onPasswordChange} value={password} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Confirm Password *</Label>
                            <Input name="confirmPassword" type="password" onChange={onConfirmPasswordChange} value={confirmPassword} />
                        </FormGroup>
                        <Button color="success" onClick={updatePassword}>
                            Change Password
                        </Button>
                    </Form>
                </Col>
            </Row>
        </CardBody>
    );
};

class TFA extends React.Component {
    constructor(props) {
        super(props);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onConfirmPasswordChange = this.onConfirmPasswordChange.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showLoadingInfo = this.showLoadingInfo.bind(this);
        this.showLoadingPassword = this.showLoadingPassword.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.state = {
            loadingPassword: false,
            loadingInfo: false,
            password: '',
            confirmPassword: '',
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            companyInfo: {
                firstName: '-',
                email: '-',
            },
            personInfo: {
                email: '-',
                username: '-',
            },
        };
    }
    modalAction() {}
    negativeModalAction() {
        this.modalToggle();
    }
    showLoadingInfo(show) {
        if (this._isMounted) {
            this.setState({
                loadingInfo: show,
            });
        }
    }
    showLoadingPassword(show) {
        this.setState({
            loadingPassword: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    onPasswordChange(e) {
        this.setState({
            password: e.target.value,
        });
    }
    onConfirmPasswordChange(e) {
        this.setState({
            confirmPassword: e.target.value,
        });
    }
    updatePassword() {
        if (this.state.password === '' || this.state.confirmPassword === '') {
            this.showModal(`Error`, `Please enter password and confirm password`, 'alert', true, true);
            return false;
        }
        if (this.state.password !== this.state.confirmPassword) {
            this.showModal(`Error`, `Password does not match confirm password`, 'alert', true, true);
            return false;
        }
        this.showLoadingPassword(true);
        this.props
            .updatePassword(this.state.password)
            .then(response => {
                this.showLoadingPassword(false);
                this.showModal(`Confirmation`, `Your password has been updated`, 'alert', true, true);
            })
            .catch(error => {
                this.showLoadingPassword(false);
                this.showModal(`Error`, `Change password is not successful`, 'alert', true, true);
            });
    }
    componentDidMount() {
        this._isMounted = true;
        const resData = JSON.parse(localStorage.getItem('resData'));
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const p1Header = (
            <div>
                <i className="fa fa-info-cirscle" />
                {' Existing Account Information'}
            </div>
        );
        const p3Header = (
            <div>
                <i className="fa fa-key" />
                {' Change Account Password'}
            </div>
        );
        return (
            <Container>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Row style={{ marginTop: '8rem' }}>
                    <Col></Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.cyderChangePasswordReducer,
        user: state.profile.user,
        fields: [
            {
                id: 'password',
                value: '',
                name: 'password',
                icon: 'lock_outline',
                type: 'password',
                errors: [],
                rules: {
                    title: 'Password',
                    required: true,
                },
            },
            {
                id: 'confirmpassword',
                value: '',
                name: 'password',
                icon: 'lock_outline',
                type: 'password',
                errors: [],
                rules: {
                    title: 'Confirm Password',
                    required: true,
                },
            },
        ],
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getCompanyInfo: () => {
            return dispatch(getCompanyInfo());
        },
        getPersonInfo: personId => {
            return dispatch(getPersonInfo(personId));
        },
        updatePassword: password => {
            return dispatch(updatePassword(password));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TFA);
