import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import { Row, Col, Container } from 'reactstrap';
import EventsAnalyticsToolbar from 'pages/analytics/components/events/EventsAnalyticsToolbar';
import NumberOfEvents from 'pages/analytics/components/events/NumberOfEvents';
import TotalShowedUp from 'pages/analytics/components/events/TotalShowedUp';
import TotalCompanyShowedUp from 'pages/analytics/components/events/TotalShowedUpCompany';
import TotalIndividualShowedUp from 'pages/analytics/components/events/TotalShowedUpIndividual';
import PercentageAverage from 'pages/analytics/components/events/PercentageAverage';
import SGBCMemberAttendees from 'pages/analytics/components/events/SGBCMemberAttendees';
import EventsSummaryList from 'pages/analytics/components/events/EventsSummaryList';

class EventDashboard extends React.Component {
    render() {
        return (
            <Container>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={12}>
                        <EventsAnalyticsToolbar />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={4}>
                        <NumberOfEvents />
                    </Col>
                    {/* <Col xs={3}>
                        <TotalRegisteredAttendees />
                    </Col> */}
                    <Col xs={4}>
                        <SGBCMemberAttendees />
                    </Col>
                    <Col xs={4}>
                        <PercentageAverage />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={4}>
                        <TotalShowedUp />
                    </Col>
                    <Col xs={4}>
                        <TotalCompanyShowedUp />
                    </Col>
                    <Col xs={4}>
                        <TotalIndividualShowedUp />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={12}>
                        <EventsSummaryList />
                    </Col>
                </Row>
            </Container>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(StoredLayout(EventDashboard));
