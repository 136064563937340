/**
 * Action Types
 *
 * Author: Fernando
 */

// Security
export const CYDER_LOGIN_REQUEST = 'CYDER_LOGIN_REQUEST';
export const CYDER_LOGIN_UPDATE_SESSION = 'CYDER_LOGIN_UPDATE_SESSION';
export const CYDER_UPDATE_USER_TFA = 'CYDER_UPDATE_USER_TFA';
export const CYDER_LOGIN_SUCCESS = 'CYDER_LOGIN_SUCCESS';
export const CYDER_LOGIN_SUCCESS_SET_PROFILE = 'CYDER_LOGIN_SUCCESS_SET_PROFILE';
export const CYDER_LOGIN_SUCCESS_MOCK_MEMBERSHIP = 'CYDER_LOGIN_SUCCESS_MOCK_MEMBERSHIP';
export const CYDER_LOGIN_FAILURE = 'CYDER_LOGIN_FAILURE';
export const CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE = 'CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE';
export const CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE = 'CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE';
export const CYDER_LOGIN_PAGE_RESET_STATE = 'CYDER_LOGIN_PAGE_RESET_STATE';
export const CYDER_LOGOUT = 'CYDER_LOGOUT';
export const CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE = 'CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE';
export const CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG = 'CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG';

export const CYDER_LOGIN_PAGE_GET_COMPANIES_REQUEST = 'CYDER_LOGIN_PAGE_GET_COMPANIES_REQUEST';
export const CYDER_LOGIN_PAGE_GET_COMPANIES_SUCCESS = 'CYDER_LOGIN_PAGE_GET_COMPANIES_SUCCESS';
export const CYDER_LOGIN_PAGE_GET_COMPANIES_FAILURE = 'CYDER_LOGIN_PAGE_GET_COMPANIES_FAILURE';

// Change password - ForgotPassword.js
export const CYDER_FORGOT_PASSWORD_REQUEST = 'CYDER_FORGOT_PASSWORD_REQUEST';
export const CYDER_FORGOT_PASSWORD_SUCCESS = 'CYDER_FORGOT_PASSWORD_SUCCESS';
export const CYDER_FORGOT_PASSWORD_FAILURE = 'CYDER_FORGOT_PASSWORD_FAILURE';

export const CYDER_CHANGE_PASSWORD_PAGE_CLEAR_MESSAGE = 'CYDER_CHANGE_PASSWORD_PAGE_CLEAR_MESSAGE';
export const CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE = 'CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE';

export const CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE = 'CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE';

// Change password - ChangePassword.js
export const CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_REQUEST = 'CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_REQUEST';
export const CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_SUCCESS = 'CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_SUCCESS';
export const CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_FAILURE = 'CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_FAILURE';

export const CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST = 'CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST';
export const CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS = 'CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS';
export const CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE = 'CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE';

export const CYDER_CHANGE_PASSWORD_HANDLE_INPUT_CHANGE = 'CYDER_CHANGE_PASSWORD_HANDLE_INPUT_CHANGE';

export const CYDER_CHANGE_PASSWORD_SET_MODAL_DATA = 'CYDER_CHANGE_PASSWORD_SET_MODAL_DATA';

// Sysparams
export const CYDER_SYSPARAMS_GET = 'CYDER_SYSPARAMS_GET';
export const CYDER_SYSPARAMS_GET_SUCCESS = 'CYDER_SYSPARAMS_GET_SUCCESS';

export const CYDER_SYSPARAMS_PAGE_MODAL_TOGGLE = 'CYDER_SYSPARAMS_PAGE_MODAL_TOGGLE';
export const CYDER_SYSPARAMS_PAGE_MODAL_OPEN = 'CYDER_SYSPARAMS_PAGE_MODAL_OPEN';
export const CYDER_SYSPARAMS_PAGE_MODAL_CLOSE = 'CYDER_SYSPARAMS_PAGE_MODAL_CLOSE';
export const CYDER_SYSPARAMS_PAGE_MODAL_CHANGE_VALUE = 'CYDER_SYSPARAMS_PAGE_MODAL_CHANGE_VALUE';
export const CYDER_SYSPARAMS_PAGE_LOADING = 'CYDER_SYSPARAMS_PAGE_LOADING';
export const CYDER_SYSPARAMS_PAGE_MODAL_SET_SYSPARAM = 'CYDER_SYSPARAMS_PAGE_MODAL_SET_SYSPARAM';

export const CYDER_SYSPARAMS_SAVE_VALUE = 'CYDER_SYSPARAMS_SAVE_VALUE';
export const CYDER_SYSPARAMS_SAVE_VALUE_SUCCESS = 'CYDER_SYSPARAMS_SAVE_VALUE_SUCCESS';
export const CYDER_SYSPARAMS_SAVE_VALUE_SAVING = 'CYDER_SYSPARAMS_SAVE_VALUE_SAVING';

// Users List
export const CYDER_USERS_SEARCH_REQUEST = 'CYDER_USERS_SEARCH_REQUEST';
export const CYDER_USERS_SEARCH_SUCCESS = 'CYDER_USERS_SEARCH_SUCCESS';
export const CYDER_USERS_SEARCH_FAILURE = 'CYDER_USERS_SEARCH_FAILURE';
export const CYDER_USERS_SEARCH_SET_FORCE_REFRESH_FLAG = 'CYDER_USERS_SEARCH_SET_FORCE_REFRESH_FLAG';

// Users Detail Page
export const CYDER_USERS_DETAIL_REQUEST = 'CYDER_USERS_DETAIL_REQUEST';
export const CYDER_USERS_DETAIL_SUCCESS = 'CYDER_USERS_DETAIL_SUCCESS';
export const CYDER_USERS_DETAIL_FAILURE = 'CYDER_USERS_DETAIL_FAILURE';
export const CYDER_USERS_DETAIL_CHANGE_FIELD = 'CYDER_USERS_DETAIL_CHANGE_FIELD';
export const CYDER_USERS_DETAIL_SAVE_CHANGES_REQUEST = 'CYDER_USERS_DETAIL_SAVE_CHANGES_REQUEST';
export const CYDER_USERS_DETAIL_SAVE_CHANGES_SUCCESS = 'CYDER_USERS_DETAIL_SAVE_CHANGES_SUCCESS';
export const CYDER_USERS_DETAIL_SAVE_CHANGES_FAILURE = 'CYDER_USERS_DETAIL_SAVE_CHANGES_FAILURE';
export const CYDER_USERS_DETAIL_DELETE_REQUEST = 'CYDER_USERS_DETAIL_DELETE_REQUEST';
export const CYDER_USERS_DETAIL_DELETE_SUCCESS = 'CYDER_USERS_DETAIL_DELETE_SUCCESS';
export const CYDER_USERS_DETAIL_DELETE_FAILURE = 'CYDER_USERS_DETAIL_DELETE_FAILURE';
export const CYDER_USERS_DETAIL_MODAL_TOGGLE = 'CYDER_USERS_DETAIL_MODAL_TOGGLE';
export const CYDER_USERS_DETAIL_FORCE_VALIDATE = 'CYDER_USERS_DETAIL_FORCE_VALIDATE';

// Users Add
export const CYDER_USERS_ADD_CHANGE_FIELD = 'CYDER_USERS_ADD_CHANGE_FIELD';
export const CYDER_USERS_ADD_MESSAGE_MODAL_TOGGLE = 'CYDER_USERS_ADD_MESSAGE_MODAL_TOGGLE';
export const CYDER_USERS_ADD_MESSAGE_MODAL_OPEN = 'CYDER_USERS_ADD_MESSAGE_MODAL_OPEN';
export const CYDER_USERS_ADD_MESSAGE_MODAL_CLOSE = 'CYDER_USERS_ADD_MESSAGE_MODAL_CLOSE';
export const CYDER_USERS_ADD_ADD_USER_FORCE_VALIDATE = 'CYDER_USERS_ADD_ADD_USER_FORCE_VALIDATE';
export const CYDER_USERS_ADD_ADD_USER_REQUEST = 'CYDER_USERS_ADD_ADD_USER_REQUEST';
export const CYDER_USERS_ADD_ADD_USER_SUCCESS = 'CYDER_USERS_ADD_ADD_USER_SUCCESS';
export const CYDER_USERS_ADD_ADD_USER_ERROR = 'CYDER_USERS_ADD_ADD_USER_ERROR';
export const CYDER_USERS_ADD_CLEAR_FIELDS = 'CYDER_USERS_ADD_CLEAR_FIELDS';

// Groups List
export const CYDER_GROUPS_SEARCH_REQUEST = 'CYDER_GROUPS_SEARCH_REQUEST';
export const CYDER_GROUPS_SEARCH_SUCCESS = 'CYDER_GROUPS_SEARCH_SUCCESS';
export const CYDER_GROUPS_SEARCH_FAILURE = 'CYDER_GROUPS_SEARCH_FAILURE';

export const CYDER_GROUPS_SEARCH_PAGE_RESET_STATE = 'CYDER_GROUPS_SEARCH_PAGE_RESET_STATE';

// Page
export const SET_NAVIGATION = 'SET_NAVIGATION';
export const SET_STAFFNAVIGATION = 'SET_STAFFNAVIGATION';
export const SET_ADMIN_STAFFNAVIGATION = 'SET_ADMIN_STAFFNAVIGATION';
export const SET_MEMBERNAVIGATION = 'SET_MEMBERNAVIGATION';
export const SET_NONMEMBERNAVIGATION = 'SET_NONMEMBERNAVIGATION';
export const PAGE_SET_JUMBOTRON_TEXT = 'PAGE_SET_JUMBOTRON_TEXT';

// Profile - profile.js
export const PERSONAL_PROFILE_GET_PROFILE_INFO_REQUEST = 'PERSONAL_PROFILE_GET_PROFILE_INFO_REQUEST';
export const PERSONAL_PROFILE_GET_PROFILE_INFO_SUCCESS = 'PERSONAL_PROFILE_GET_PROFILE_INFO_SUCCESS';
export const PERSONAL_PROFILE_GET_PROFILE_INFO_FAILURE = 'PERSONAL_PROFILE_GET_PROFILE_INFO_FAILURE';

export const PERSONAL_PROFILE_SAVE_PROFILE_INFO_REQUEST = 'PERSONAL_PROFILE_SAVE_PROFILE_INFO_REQUEST';
export const PERSONAL_PROFILE_SAVE_PROFILE_INFO_SUCCESS = 'PERSONAL_PROFILE_SAVE_PROFILE_INFO_SUCCESS';
export const PERSONAL_PROFILE_SAVE_PROFILE_INFO_FAILURE = 'PERSONAL_PROFILE_SAVE_PROFILE_INFO_FAILURE';

export const PERSONAL_PROFILE_RESET_TO_DEFAULT = 'PERSONAL_PROFILE_RESET_TO_DEFAULT';
export const PERSONAL_PROFILE_HANDLE_INPUT_CHANGE = 'PERSONAL_PROFILE_HANDLE_INPUT_CHANGE';
export const PERSONAL_PROFILE_SET_MODAL_DATA = 'PERSONAL_PROFILE_SET_MODAL_DATA';

// Cyder Dev
export const CYDER_PROFILE_SET_ADMIN_MODE = 'CYDER_PROFILE_SET_ADMIN_MODE';
export const CYDER_PROFILE_REMOVE_ADMIN_MODE = 'CYDER_PROFILE_REMOVE_ADMIN_MODE';
