import StoredLayout from 'layouts/StoredLayout';
import React from 'react';
import { connect } from 'react-redux';
import {
    Alert,
    Button,
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    CardText,
    Form,
    FormGroup,
    FormText,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { getCompanyInfo, getPersonInfo, updatePassword, generateOTP, verifyOTP, disableTwoFactorAuth } from 'actions/profile/action';
import DialogModal from 'cyder/modals/DialogModal';
import QRCode from 'qrcode';
import 'css/cyder/panel/standard-4080-panel.css';
import { CYDER_UPDATE_USER_TFA } from '../../../actions/constants/actionTypes';

const styles = {
    heading3: `text-xl font-semibold text-gray-900 p-4 border-b`,
    heading4: `text-base text-ct-blue-600 font-medium border-b mb-2`,
    modalOverlay: `overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full`,
    orderedList: `space-y-1 text-sm list-decimal`,
    buttonGroup: `flex items-center py-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600`,
    buttonBlue: `text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`,
    buttonGrey: `text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600`,
    inputField: `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/5 p-2.5`,
};

const GenericPanel = props => {
    return (
        <Row className="d-flex justify-content-center">
            <div style={{ marginBottom: '20px', width: '100%' }}>
                {/* <div className="standard-4080-panel"> */}
                <CardTitle>{props.headerContent}</CardTitle>
                {props.children}
                {/* </div> */}
            </div>
        </Row>
    );
};

const Panel3Content = ({ updatePassword, onPasswordChange, onConfirmPasswordChange, password, confirmPassword }) => {
    return (
        <CardBody>
            <Row>
                <Col md={12}>
                    <Form>
                        <FormGroup>
                            <Label>Password *</Label>
                            <Input name="password" type="password" onChange={onPasswordChange} value={password} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Confirm Password *</Label>
                            <Input name="confirmPassword" type="password" onChange={onConfirmPasswordChange} value={confirmPassword} />
                        </FormGroup>
                        <Button color="success" onClick={updatePassword}>
                            Change Password
                        </Button>
                    </Form>
                </Col>
            </Row>
        </CardBody>
    );
};

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onConfirmPasswordChange = this.onConfirmPasswordChange.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.generateOTP = this.generateOTP.bind(this);
        this.verifyOTP = this.verifyOTP.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showLoadingInfo = this.showLoadingInfo.bind(this);
        this.showLoadingPassword = this.showLoadingPassword.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.toggle = this.toggle.bind(this);

        this.state = {
            loadingPassword: false,
            loadingInfo: false,
            companyInfo: {
                firstName: '-',
                email: '-',
            },
            personInfo: {
                email: '-',
                username: '-',
            },
            password: '',
            confirmPassword: '',
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            token: null,
            tfa: {
                base32: null,
                otpauth_url: null,
                qrcodeUrl: null,
                otp_enabled: false,
            },
            isOpenTFAModal: false,
        };
    }
    toggle() {
        console.log('!this.state.isOpenTFAModal', !this.state.isOpenTFAModal);
        this.setState({
            isOpenTFAModal: !this.state.isOpenTFAModal,
        });
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoadingInfo(show) {
        this.setState({
            loadingInfo: show,
        });
    }
    showLoadingPassword(show) {
        this.setState({
            loadingPassword: show,
        });
    }
    modalAction() {}
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    onPasswordChange(e) {
        this.setState({
            password: e.target.value,
        });
    }
    onConfirmPasswordChange(e) {
        this.setState({
            confirmPassword: e.target.value,
        });
    }
    updatePassword() {
        if (this.state.password === '' || this.state.confirmPassword === '') {
            this.showModal(`Error`, `Please enter password and confirm password`, 'alert', true, true);
            return false;
        }
        if (this.state.password !== this.state.confirmPassword) {
            this.showModal(`Error`, `Password does not match confirm password`, 'alert', true, true);
            return false;
        }
        this.showLoadingPassword(true);
        this.props
            .updatePassword(this.state.password)
            .then(response => {
                this.showLoadingPassword(false);
                this.showModal(`Confirmation`, `Your password has been updated`, 'alert', true, true);
            })
            .catch(error => {
                this.showLoadingPassword(false);
                this.showModal(`Error`, `Change password is not successful`, 'alert', true, true);
            });
    }
    disableTwoFactorAuth({ username }) {
        this.showLoadingPassword(true);
        this.props
            .disableTwoFactorAuth(username)
            .then(res => {
                this.showLoadingPassword(false);
                // this.showModal(`Confirmation`, `Your OTP has been generated`, 'alert', true, true);
                if (res.otp_enabled !== undefined && res.otp_enabled !== null) {
                    const otp_enabled = res.otp_enabled === false ? 0 : 1;
                    this.props.user.otp_enabled = otp_enabled;
                    this.props.updateTFA(otp_enabled);
                    console.log('this.props.user.otp_enabled', this.props.user.otp_enabled);
                    this.setState({
                        tfa: {
                            otp_enabled: otp_enabled,
                        },
                    });
                }
            })
            .catch(error => {
                this.showLoadingPassword(false);
                // this.showModal(`Error`, `Cannot generate OTP`, 'alert', true, true);
            });
    }
    generateOTP({ username, email }) {
        this.showLoadingPassword(true);
        this.props
            .generateOTP(username)
            .then(res => {
                this.showLoadingPassword(false);
                // this.showModal(`Confirmation`, `Your OTP has been generated`, 'alert', true, true);
                if (res.base32 !== undefined && res.base32 !== null && res.otpauth_url !== undefined && res.otpauth_url !== null) {
                    const base32 = res.base32;
                    const otpauth_url = res.otpauth_url;

                    QRCode.toDataURL(otpauth_url).then(data => {
                        this.toggle();
                        this.setState({
                            tfa: {
                                base32: base32,
                                otpauth_url: otpauth_url,
                                qrcodeUrl: data,
                            },
                        });
                    });
                } else {
                    this.showLoadingPassword(false);
                    this.showModal(`Error`, `Cannot generate OTP`, 'alert', true, true);
                }
            })
            .catch(error => {
                this.showLoadingPassword(false);
                this.showModal(`Error`, `Cannot generate OTP`, 'alert', true, true);
            });
    }
    verifyOTP({ username, token }) {
        this.showLoadingPassword(true);
        if (username !== undefined && username !== null && token !== undefined && token !== null) {
            this.props
                .verifyOTP(username, token)
                .then(res => {
                    this.showLoadingPassword(false);
                    console.log('res', res);
                    if (res.otp_enabled !== undefined && res.otp_enabled !== null) {
                        const otp_enabled = res.otp_enabled;
                        if (otp_enabled) {
                            this.props.user.otp_enabled = otp_enabled;
                            this.props.updateTFA(otp_enabled);
                            this.setState({
                                tfa: {
                                    otp_enabled: otp_enabled,
                                },
                            });
                            this.toggle();
                        } else {
                            this.showModal(`Error`, `Cannot Verify OTP`, 'alert', true, true);
                            this.showLoadingPassword(false);
                        }
                    }
                })
                .catch(error => {
                    this.showLoadingPassword(false);
                    this.showModal(`Error`, `Cannot Verify OTP`, 'alert', true, true);
                });
        } else {
            this.showLoadingPassword(false);
            this.showModal(`Error`, `Cannot Verify OTP`, 'alert', true, true);
        }
    }
    componentDidMount() {
        this.showLoadingInfo(true);
        this.props
            .getCompanyInfo()
            .then(companyInfo => {
                this.showLoadingInfo(false);
                if (companyInfo != null && companyInfo.data != null) {
                    if (companyInfo.data.officialManagementRepresentativeId != null) {
                        this.props
                            .getPersonInfo(companyInfo.data.officialManagementRepresentativeId)
                            .then(officialRep => {
                                var companyInfo = {
                                    email: officialRep.data.email,
                                    firstName: officialRep.data.firstName,
                                };
                                this.setState({
                                    companyInfo,
                                });
                            })
                            .catch(error => {});
                    }
                }
            })
            .catch(error => {
                this.showLoadingInfo(false);
            });

        var personInfo = {
            username: this.props.user.email,
            email: this.props.user.email,
        };

        console.log('this.props.user', this.props.user);

        var tfa = {
            otp_enabled: this.props.user.otp_enabled || false,
        };

        this.setState({
            personInfo,
            tfa,
        });
    }

    render() {
        const p1Header = (
            <div>
                <i className="fa fa-info-cirscle" />
                {' Existing Account Information'}
            </div>
        );
        const p3Header = (
            <div>
                <i className="fa fa-key" />
                {' Change Account Password'}
            </div>
        );
        return (
            <>
                <div>
                    <DialogModal
                        modalAction={this.modalAction}
                        negativeButtonAction={this.negativeModalAction}
                        actionToggleExternal={this.modalToggle}
                        modalOpen={this.state.modal.showModal}
                        positiveButtonHide={this.state.modal.positiveButtonHide}
                        negativeButtonHide={this.state.modal.negativeButtonHide}
                        modalBody={this.state.modal.modalMessage}
                        modalHeader={this.state.modal.modalHeader}
                    />
                    <Row>
                        <Col>
                            {this.state.loadingPassword ? (
                                <LoadingSpinner />
                            ) : (
                                <GenericPanel headerContent={p3Header}>
                                    <Panel3Content
                                        updatePassword={this.updatePassword}
                                        onPasswordChange={this.onPasswordChange}
                                        onConfirmPasswordChange={this.onConfirmPasswordChange}
                                        password={this.state.password}
                                        confirmPassword={this.state.confirmPassword}
                                    />
                                </GenericPanel>
                            )}
                        </Col>
                        <Col>
                            <section className="bg-ct-blue-600  min-h-screen pt-10">
                                <div className="max-w-4xl p-12 mx-auto bg-ct-dark-100 rounded-md h-[20rem] flex gap-20 justify-center items-start">
                                    <div>
                                        <h3 className="text-2xl font-semibold">Mobile App Authentication (2FA)</h3>
                                        <p className="mb-4">Secure your account with TOTP two-factor authentication.</p>
                                        {this.state.tfa.otp_enabled ? (
                                            <button
                                                type="button"
                                                className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
                                                onClick={() => this.disableTwoFactorAuth({ username: this.state.personInfo.username })}
                                            >
                                                Disable 2FA
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none"
                                                onClick={() => {
                                                    this.generateOTP({
                                                        username: this.state.personInfo.username,
                                                        email: this.state.personInfo.email,
                                                    });
                                                }}
                                            >
                                                Setup 2FA
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </Col>
                    </Row>
                </div>
                {this.state.isOpenTFAModal && (
                    <Modal isOpen={this.state.isOpenTFAModal} toggle={this.toggle}>
                        <ModalHeader className={'plr-6 '} toggle={this.toggle}>
                            Two-Factor Authentication (2FA)
                        </ModalHeader>
                        <ModalBody style={{ overflowY: 'auto' }}>
                            <div className="relative w-full max-w-xl h-full md:h-auto left-1/2 -translate-x-1/2">
                                <div className="relative">
                                    {/* Modal body */}
                                    <div className="plr-6 ptb-2 space-y-4">
                                        <h4 className={styles.heading4}>Configuring Google Authenticator</h4>
                                        <div className={styles.orderedList}>
                                            <li>Install Google Authenticator (IOS - Android)</li>
                                            <li>In the authenticator app, select "+" icon.</li>
                                            <li>Select "Scan a barcode (or QR code)" and use the phone's camera to scan this barcode.</li>
                                        </div>
                                        <div>
                                            <h4 className={styles.heading4}>Scan QR Code</h4>
                                            <div className="flex justify-center">
                                                <img
                                                    className="block w-64 h-64 object-contain"
                                                    src={this.state.tfa.qrcodeUrl}
                                                    alt="qrcode url"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className={styles.heading4}>Or Enter Code Into Your App</h4>
                                            <p className="text-sm">SecretKey: {this.state.tfa.base32} (Base32 encoded)</p>
                                        </div>
                                        <div>
                                            <h4 className={styles.heading4}>Verify Code</h4>
                                            <p className="text-sm">For changing the setting, please verify the authentication code:</p>
                                        </div>
                                        <input
                                            onBlur={e => {
                                                const value = e.target.value;
                                                this.setState({
                                                    token: value,
                                                });
                                            }}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/4 p-2.5"
                                            placeholder="Authentication Code"
                                        />
                                        <p className="mt-2 text-xs text-red-600">{/* {errors.token ? errors.token.message : null} */}</p>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" onClick={this.toggle} className={styles.buttonGrey}>
                                Close
                            </Button>
                            <Button
                                className={styles.buttonBlue}
                                onClick={() => {
                                    this.verifyOTP({ username: this.state.personInfo.username, token: this.state.token });
                                }}
                            >
                                Verify & Activate
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.cyderChangePasswordReducer,
        user: state.profile.user,
        fields: [
            {
                id: 'password',
                value: '',
                name: 'password',
                icon: 'lock_outline',
                type: 'password',
                errors: [],
                rules: {
                    title: 'Password',
                    required: true,
                },
            },
            {
                id: 'confirmpassword',
                value: '',
                name: 'password',
                icon: 'lock_outline',
                type: 'password',
                errors: [],
                rules: {
                    title: 'Confirm Password',
                    required: true,
                },
            },
        ],
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getCompanyInfo: () => {
            return dispatch(getCompanyInfo());
        },
        getPersonInfo: personId => {
            return dispatch(getPersonInfo(personId));
        },
        updatePassword: password => {
            return dispatch(updatePassword(password));
        },
        generateOTP: username => {
            return dispatch(generateOTP(username));
        },
        verifyOTP: (username, token) => {
            return dispatch(verifyOTP(username, token));
        },
        disableTwoFactorAuth: username => {
            return dispatch(disableTwoFactorAuth(username));
        },
        updateTFA: otp_enabled => {
            return dispatch({ type: CYDER_UPDATE_USER_TFA, otp_enabled: otp_enabled });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoredLayout(ChangePassword));
