/**
 * Member services application reducer
 *
 * Author: Fernando Karnagi
 */

var defaultState = {
    preview: {
        submitConfirmationVisible: false,
    },
    view: {
        applicationNo: '12345',
        finalRating: '4',
        company: 'Facebook Inc.',
        status: '',
        takeUpCaseStarted: false,
        assignApproverStarted: false,
        assignNextApproverStarted: false,
        submittedOn: 'June 1, 2018, 7:53 p.m.',
        step: 0,
    },
    new: {
        saveConfirmationVisible: false,
        currentTabNo: 0,
        activeTabs: [true, false, false, false, false],
        selectedServiceCategory: '',
        serviceCategories: [],
        selectedEpcSelfFinancingCapable: '',
        epcSelfFinancingCapableOptions: [
            {
                value: 'Yes',
                label: 'Yes',
            },
            {
                value: 'No',
                label: 'No',
            },
        ],
    },
};

export function memberServiceApplicationReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'MEMBER_SERVICE_SET_SELECTED_SERVICE_CATEGORY':
            newState.new = Object.assign({}, state.new);
            newState.new.selectedServiceCategory = action.selectedServiceCategory;
            return newState;
        case 'MEMBER_SERVICE_APPLICATION_NEXT':
            if (state.new.currentTabNo === 4) {
                return newState;
            }
            newState.new.currentTabNo = state.new.currentTabNo + 1;
            for (let i in state.new.activeTabs) {
                if (i === newState.new.currentTabNo) {
                    state.new.activeTabs[i] = true;
                } else {
                    state.new.activeTabs[i] = false;
                }
            }

            return newState;
        case 'MEMBER_SERFVICE_APPLICATION_GO_TO_PAGE':
            var page = action.page;
            newState.new.currentTabNo = page;
            for (let i in state.new.activeTabs) {
                if (i === newState.new.currentTabNo) {
                    state.new.activeTabs[i] = true;
                } else {
                    state.new.activeTabs[i] = false;
                }
            }

            return newState;
        case 'MEMBER_SERVICE_APPLICATION_PREV':
            if (state.new.currentTabNo === 0) {
                return newState;
            }
            newState.new.currentTabNo = state.new.currentTabNo - 1;
            for (let i in state.new.activeTabs) {
                if (i === newState.new.currentTabNo) {
                    state.new.activeTabs[i] = true;
                } else {
                    state.new.activeTabs[i] = false;
                }
            }
            return newState;
        case 'MEMBER_SERVICE_APPLICATION_RESET_FORM':
            newState.new.currentTabNo = 0;
            newState.new.activeTabs = [true, false, false, false, false];
            return newState;
        case 'MEMBER_SERVICE_SET_SELECTED_EPC_SELF_FINANCING_CAPABLE':
            newState.new = Object.assign({}, state.new);
            newState.new.selectedEpcSelfFinancingCapable = action.selectedEpcSelfFinancingCapable;
            return newState;
        case 'MEMBER_SERVICE_SET_TAKE_UP_CASE_STARTED':
            newState.view = Object.assign({}, state.view);
            newState.view.takeUpCaseStarted = action.takeUpCaseStarted;
            return newState;
        case 'MEMBER_SERVICE_SET_ASSIGN_APPROVER_STARTED':
            newState.view = Object.assign({}, state.view);
            newState.view.assignApproverStarted = action.assignApproverStarted;
            return newState;
        case 'MEMBER_SERVICE_SET_ASSIGN_NEXT_APPROVER_STARTED':
            newState.view = Object.assign({}, state.view);
            newState.view.assignNextApproverStarted = action.assignNextApproverStarted;
            return newState;
        default:
            return newState;
    }
}
