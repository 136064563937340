import React from 'react';
import cyderlib from 'js/cyderlib.js';

import { Button } from 'reactstrap';

const LegendIcon = props => {
    const status = props.status ? props.status.toLowerCase() : '';
    let color;
    let count;
    let icon;
    let text;
    let clicked;
    if (props.module === 'membership') {
        color = props.color || cyderlib.getApplicationStatusAttr(status, 'color') || 'default';
        icon = props.icon || cyderlib.getApplicationStatusAttr(status, 'icon');
        text = props.text || cyderlib.getApplicationStatusAttr(status, 'text') || '';
        count = null;
        clicked = props.clicked;
    } else if (props.module === 'galadinner') {
        color = props.color || cyderlib.getApplicationStatusAttr(status, 'color') || 'default';
        icon = props.icon || cyderlib.getApplicationStatusAttr(status, 'icon');
        count = props.count || cyderlib.getApplicationStatusAttr(status, 'count') || 0;
        text = props.text || cyderlib.getApplicationStatusAttr(status, 'text') || '';
        clicked = props.clicked;
        count = ' (' + count + ')';
    } else {
        color = props.color || cyderlib.getProdCertApplicationStatusAttr(status, 'color') || 'default';
        icon = props.icon || cyderlib.getProdCertApplicationStatusAttr(status, 'icon') || 'check';
        text = props.text || cyderlib.getProdCertApplicationStatusAttr(status, 'text') || '';
        clicked = null;
        count = null;
    }
    return (
        <span>
            {!props.noIcon && (
                <Button
                    onClick={props.onClick}
                    className={
                        props.customColor
                            ? ``
                            : clicked === null
                            ? 'primary-btn-style'
                            : clicked === text
                            ? 'primary-btn-style-selected'
                            : 'primary-btn-style'
                    }
                    style={{
                        marginBottom: '5px',
                        backgroundColor: props.customColor ? props.color : 'initial',
                        borderColor: props.customColor ? props.color : 'initial',
                        cursor: props.onClick ? 'pointer' : 'initial',
                    }}
                >
                    {icon && <i className={'material-icons align-text-bottom text-white'}>{icon}</i>}
                    {props.bold && !props.hideText && (
                        <b>
                            &nbsp;
                            {text}
                            {count !== null && count}
                            &nbsp;&nbsp;&nbsp;
                        </b>
                    )}
                    {!props.bold && !props.hideText && (
                        <b>
                            &nbsp;
                            {text}
                            {count !== null && count}
                            &nbsp;&nbsp;&nbsp;
                        </b>
                    )}
                </Button>
            )}
        </span>
    );
};

export default LegendIcon;
