import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Container, Col, NavbarBrand, Navbar } from 'reactstrap';
import { cyderLogoutAction } from 'actions/security/cyderSecurityAction';
import { memberMembershipApplicationGetCompanyInfo } from 'actions/member/company/memberCompanyAction';
import { personalProfileGetProfileInfo } from 'actions/profile/personalProfileAction';
import { isMobile } from 'react-device-detect';
import { sanitizeStringAndObjectNullsToEmptyStrings, isEmpty } from 'js/util';

import { CYDER_LOGIN_UPDATE_SESSION } from 'actions/constants/actionTypes';

import '../../css/elements/top-navigation-2.css';

const Menu = ({ navigation, logout, profile, setActiveTab, activeTab }) => {
    return (
        <ul className="list-unstyled">
            <li className="list-item">
                <Link
                    style={{
                        // SET NAVIGATION HIGHTLIGHT
                        color: activeTab === navigation.title ? '#78be20' : '',
                    }}
                    to={navigation.url || '#'}
                    className="list-link d-flex align-items-center"
                    // onClick={setActiveTab}
                    onClick={() => {
                        // RELOAD PAGE IF 'URL TO' AND 'CURRENT URL' IS THE SAME
                        if (window.location.pathname.includes(navigation.url)) window.location.reload();
                        setActiveTab();
                    }}
                >
                    <strong>
                        <span>{navigation.title}</span>
                    </strong>
                </Link>
                <ul className="list-unstyled d-flex flex-column">
                    {navigation.items &&
                        navigation.items.map((item, i) => {
                            if (item.url === '/member/membership/newapplication') {
                                if (
                                    profile.is_cert_coordinator === 1 ||
                                    profile.is_corporate_admin === 1 ||
                                    profile.is_omr === 1 ||
                                    profile.is_secondarymr === 1
                                ) {
                                    return (
                                        <li className="list-item" key={i}>
                                            <Link
                                                onClick={() => {
                                                    if (item.action === 'logout') logout();
                                                }}
                                                to={item.url}
                                                className="list-link"
                                            >
                                                {item.title}
                                            </Link>
                                        </li>
                                    );
                                }
                            } else {
                                return (
                                    <li className="list-item" key={i}>
                                        {item.external ? (
                                            <Link
                                                onClick={e => {
                                                    window.open(item.url);
                                                }}
                                                to={``}
                                                className="list-link"
                                            >
                                                {item.title}
                                            </Link>
                                        ) : (
                                            <Link
                                                onClick={() => {
                                                    if (item.action === 'logout') logout();
                                                }}
                                                to={item.url}
                                                className="list-link"
                                            >
                                                {item.title}
                                            </Link>
                                        )}
                                    </li>
                                );
                            }
                        })}
                </ul>
            </li>
        </ul>
    );
};

class TopNavigation2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            companyInfo: {},
            isMobileMenuOpen: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getRelevantData();
        this.checkCorporateProfile();
    }
    componentDidUpdate(prevProps) {
        // RESET activeTab WHEN SWITCH FROM PUBLIC TO MEMBER SITE
        if (prevProps.isMember !== this.props.isMember) {
            this.getRelevantData();
            this.props.setActiveTab('Account');
        }

        if (this.props.login.refreshTopNav) {
            this.getRelevantData();
            this.props.updateSession(false);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    async checkCorporateProfile() {
        const membershipInfoRes = await this.props.getMembershipInfo();
        if (membershipInfoRes !== null) {
            const companyInfo = sanitizeStringAndObjectNullsToEmptyStrings(membershipInfoRes.data);
            if (this._isMounted)
                this.setState({
                    companyInfo,
                });
        }
    }
    async getRelevantData() {
        try {
            if (this.props.profile.authorizationToken !== null) {
                // Reason for commenting:   Causing TFA error whenever user request for TFA and refreshes without completing the verification.
                //                          The getMembershipInfo() requires an `id` but here it is never supplied, so code is redundant as well.
                //
                this.props.getPersonalProfile();
            }
        } catch (error) {
            throw error;
        }
    }
    render() {
        // profileInfo is retrieved and triggered on MemberHomeOld.js
        const { firstName, lastName, email } = this.props.profileInfo;
        const { isMobileMenuOpen } = this.state;
        const { company_name } = this.props.profile.user;
        const printCompanyInfoNotComplete = companyInfo => {
            if (!companyInfo || isEmpty(companyInfo)) return;
            const { organisationMainCategoryId, businessAddressLine1 } = companyInfo;
            if (!organisationMainCategoryId || !businessAddressLine1) {
                return (
                    <div className="not-complete" style={{ marginLeft: '-2px', color: '#63666a' }}>
                        <Container className="pb-0">
                            Complete your corporate profile to proceed with membership/ certification application. Update your company
                            profile &nbsp;
                            <Link to="/member/myaccount/companyprofile">
                                <span className="text-bold" style={{ color: '#d32f2f' }}>
                                    HERE
                                </span>
                            </Link>
                            .
                        </Container>
                    </div>
                );
            }
            return;
        };

        var homeUrl =
            this.props.profile !== null &&
            this.props.profile.profile !== null &&
            this.props.profile.profile.logintime !== null &&
            this.props.profile.profile.errormessage == null
                ? `/member/myaccount/dashboard`
                : `/`;

        return (
            <React.Fragment>
                <div className="main-nav bg-c-newdark" style={{ zIndex: 100 }}>
                    <Container>
                        <Navbar className="top-navigation top-navigation-2 navbar-2 bg-c-newdark d-flex justify-content-around align-items-center flex-nowrap">
                            <a href="#" id="menu-icon" />
                            <NavbarBrand to={homeUrl} tag={Link}>
                                <Row>
                                    <h2 className="m-4">
                                        <strong>
                                            <span className="color-white">SGBC</span>
                                            <span style={{ color: '#78be20' }}>Online</span>
                                        </strong>
                                    </h2>
                                </Row>
                            </NavbarBrand>
                            <div className="separator" />
                            {!isMobile &&
                                this.props.navigation.map((item, i) => {
                                    return (
                                        <Menu
                                            key={i}
                                            navigation={item}
                                            logout={this.props.logout}
                                            activeTab={this.props.activeTab}
                                            profile={this.props.profile.user}
                                            setActiveTab={() => this.props.setActiveTab(item.title)}
                                        />
                                    );
                                })}

                            {isMobile && (
                                <i
                                    className="material-icons align-text-bottom"
                                    style={{
                                        fontSize: 35,
                                        color: 'white',
                                    }}
                                    onClick={e => {
                                        this.setState({
                                            isMobileMenuOpen: !this.state.isMobileMenuOpen,
                                        });
                                    }}
                                >
                                    list
                                </i>
                            )}

                            {isMobile && isMobileMenuOpen && (
                                <div style={{ width: '100%', position: 'fixed', top: '70px', background: '#424242    ' }}>
                                    {this.props.navigation.map((item, i) => {
                                        return (
                                            <a
                                                onClick={e => {
                                                    if (item.action === 'logout') {
                                                        this.props.logout();
                                                        window.location.href = `${item.url}`;
                                                    } else {
                                                        window.location.href = `${item.url}`;
                                                    }
                                                }}
                                                style={{
                                                    color: 'white',
                                                    display: 'block',
                                                    padding: '12px',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                {item.title}
                                            </a>
                                        );
                                    })}
                                </div>
                            )}
                        </Navbar>
                    </Container>
                </div>

                {this.props.isMember && (
                    <div
                        style={{
                            marginTop: '10px',
                            fontSize: '16px',
                            margin: '15px',
                        }}
                        className={`top-navigation navbar-2 d-flex flex-nowrap`}
                    >
                        <Container>
                            <Row>
                                <Col
                                    md={6}
                                    style={{
                                        color: '#317446',
                                        padding: '0px',
                                    }}
                                >
                                    <h1>
                                        <strong> {company_name !== null && company_name.toUpperCase()} &nbsp; </strong>
                                    </h1>
                                </Col>
                                <Col
                                    md={6}
                                    style={{
                                        textAlign: 'right',
                                        position: 'relative',
                                        padding: '0px',
                                    }}
                                >
                                    {firstName ? 'Welcome, ' : ''}
                                    <strong>
                                        <span style={{ color: '#317446' }}>
                                            {firstName === null || firstName === 'null' ? email : firstName.toUpperCase()}{' '}
                                            {lastName === null || lastName === 'null' ? '' : lastName.toUpperCase()}
                                        </span>
                                    </strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="p-0">{printCompanyInfoNotComplete(this.state.companyInfo)}</Col>
                            </Row>
                        </Container>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.profile,
        login: state.cyderLoginReducer,
        ...state.personalProfileReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(cyderLogoutAction());
        },
        getMembershipInfo: () => {
            return dispatch(memberMembershipApplicationGetCompanyInfo());
        },
        updateSession: () => {
            return dispatch({ type: CYDER_LOGIN_UPDATE_SESSION, action: false });
        },
        getPersonalProfile: () => {
            return dispatch(personalProfileGetProfileInfo());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation2);
