/**
 * Service certification renewal panel
 *
 * Author: Fernando Karnagi
 */
import React from 'react';
import moment from 'moment';
import { Row, Col, Card, CardBody } from 'reactstrap';

import ButtonIcon from 'cyder/button/ButtonIcon';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import '../../../../../../css/ui-elements/buttons.css';
import '../../../../../../css/icons/material-design-icons.css';
import '../../../../../../css/cyder/form/cyder-icons.css';
import '../../../../../../css/cyder/form/common-form.css';
import '../../../../../../css/cyder/tooltip/menucool.css';
import '../../../../../../css/ui-elements/buttons.css';
import '../../../../../../css/cyder/table/dashboard-table.css';

const InputField = ({ header, label }) => (
    <Row>
        <Col xs="6">
            <div className="form-group">
                <label className="field-header">{header + ':'}</label>
            </div>
        </Col>
        <Col xs="6">
            <div className="form-group">
                <div className="field-label">{label}</div>
            </div>
        </Col>
    </Row>
);

export const ServiceCertificationRenewPanel = ({
    certificate,
    assessments,
    assessmentBeingUpdated,
    viewAssessment,
    submitAssessment,
    shouldSubmitAssessmentDisabled,
    tabState,
    toggle,
    submitRenewal,
}) => {
    const mapDate = date => moment(date, 'YYYY-MM-DD').format('DD MMM YYYY');
    return (
        <React.Fragment>
            <Card className="p-2">
                <CardBody>
                    <Row>
                        <Col xs="6">
                            <InputField header="Certificate Number" label={certificate && certificate.certificateNumber} />
                            <InputField header="Valid From" label={certificate && mapDate(certificate.validFrom)} />
                            <InputField header="Valid Until" label={certificate && mapDate(certificate.validTo)} />
                        </Col>
                        <Col>
                            <Row>
                                <Col>{<ButtonIcon icon="send" width="220px" onClick={submitRenewal} label="Save and Start Renewal" />}</Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <br />
            <Card>
                <CardBody>
                    <NoShadowPanel title={'Services Information'}>
                        <br />
                        <Row>
                            <Col xs="3">
                                <div className="form-group">
                                    <label className="field-header">Services Category</label>
                                </div>
                            </Col>
                            <Col xs="6">
                                <div className="form-group">
                                    <div className="field-label">{certificate.serviceCategoryValue}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="3">
                                <div className="form-group">
                                    <label className="field-header">Are you EPC self-financing capable?</label>
                                </div>
                            </Col>
                            <Col xs="6">
                                <div className="form-group">
                                    <div className="field-label">{certificate.isSelfFinancingCapable === 1 ? 'Yes' : 'No'}</div>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        {certificate.serviceCategoryId === 0 && <div>No service information were found</div>}
                    </NoShadowPanel>
                </CardBody>
            </Card>

            <Row className="text-right">
                <Col>
                    <ButtonIcon
                        icon="bookmark"
                        disabled={shouldSubmitAssessmentDisabled} // temp disable because templateId and assessmentId is null, undefined or empty string
                        onClick={viewAssessment}
                        label="Start Assessment"
                        className="primary-btn-style mt-3"
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};
