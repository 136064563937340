import {
    MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_REQUEST,
    MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_SUCCESS,
    MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_FAILURE,
} from 'actions/constants/actionTypesMemberCertification';

import withQuery from 'with-query';
import { SGBC_S3_HOST, SGBC_API_CORS_ROOT } from 'config.js';
import fetchlib from 'js/fetchlib';

export function printCert(certificateId, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: `print-${type}-cert`,
            certificateId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_REQUEST,
            MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_SUCCESS,
            MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_FAILURE,
        );
    };
}

export function handleRegenerate(certificateId, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: `generate-${type}-cert`,
            certificateId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function handleDelete(certificateId, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: `delete-cert`,
            certificateId,
            type,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function findCertByCertNumber(certificationNumber) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: `find-by-cert-number`,
            certificationNumber,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function handleRegenerateByCertNumber(certificateNumber, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: `generate-${type}-cert`,
            certificateNumber,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function downloadCertificate(certificateId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: `find-by-id`,
            certificateId,
        });
        return fetchlib
            .fetch(dispatch, getState, 'POST', url, null, null, null, null)
            .then(res => {
                if (res != null && res.data != null && res.data.certificatePdf != null) {
                    window.open(SGBC_S3_HOST + '/certification/' + res.data.certificatePdf + '?refresh=' + new Date().getTime());
                } else {
                    alert('Certificate could not be downloaded');
                }
            })
            .catch(err => {
                console.log(err);
                alert('Certificate could not be downloaded');
            });
    };
}

export function printCertByCertNumber(certificateNumber, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: `print-${type}-cert`,
            certificateNumber,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_REQUEST,
            MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_SUCCESS,
            MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_FAILURE,
        );
    };
}
