import React from 'react';
import Logo from '../logo';
import ToggleLayout1 from './ToggleLayout1';
import ToggleLayout2 from './ToggleLayout2';
import DropdownUser from './DropdownUser';
import '../../css/elements/navbar-1.css';
import './NavBar1.css';

const Navbar1 = ({ user, config }) => (
    <nav className="navbar navbar-1 d-flex justify-content-around align-items-center flex-nowrap">
        <Logo
            src={config.layout === 'default-sidebar-1' ? '/assets/sgbc/SGBCOnline_Logo_Staff.png' : '/assets/sgbc/sgbc-logo-leaf.png'}
            size={config.layout === 'default-sidebar-1' ? '100%' : '80%'}
        />
        <ToggleLayout1 />
        <ToggleLayout2 />
        {/* <ul className="nav nav-inline nav-inline-1">
            <li className="nav-item nav-item-dropdown">
                <a className="nav-link">
                    <i className="material-icons">fullscreen</i>
                </a>
                <SGBCModulesDropdown />
            </li>
            <li className="nav-item nav-item-dropdown">
                <a className="nav-link nav-link-badge">
                    <i className="material-icons">rss_feed</i>
                    <span className="badge badge-sm badge-rounded badge-danger">
                        2
                    </span>
                </a>
                <DropdownTasks />
            </li>
            <li className="nav-item nav-item-dropdown">
                <a className="nav-link nav-link-badge">
                    <i className="material-icons">notifications_none</i>
                    <span className="badge badge-sm badge-rounded badge-danger">
                        4
                    </span>
                </a>
                <SGBCMessagesDropdown />
            </li>
        </ul> */}
        <div className="separator" />
        {/* <Search /> */}
        <ul className="nav nav-inline nav-inline-2">
            <li className="nav-item nav-item-dropdown">
                <a className="nav-link nav-link-avatar">
                    <span className="nav-item navbar-text">Welcome, {user.first_name}</span>
                </a>
                <DropdownUser />
            </li>
            <li style={{ width: '0px' }} className="nav-item nav-item-dropdown">
                <a style={{ width: '0px' }} className="nav-link">
                    <span style={{ width: '0px' }} className="flag flag-icon-background flag-icon flag-icon-gb" />
                </a>
                {/* <DropdownFlags /> */}
            </li>
        </ul>
    </nav>
);

export default Navbar1;
