/**
 * Certifications List
 *
 * Author: Fernando Karnagi
 */
import React from 'react';

import { Row, Col } from 'reactstrap';
import CertificationsReactTable from './CertificationsReactTable';
import { Container } from 'semantic-ui-react';

class Certifications extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        let title = this.props.mode === 'product' ? 'PRODUCT' : 'SERVICE';
        return (
            <Row className="mb-2">
                <Col xs={12}>
                    <br />
                    <Row className="mb-4">
                        <Col xs={12} className="d-flex">
                            <h2>
                                <strong>{title + ' CERTIFICATES'}</strong>{' '}
                            </h2>
                        </Col>
                    </Row>
                    <br />
                    <Col
                        xs={6}
                        className="d-flex"
                        style={{
                            color: '#317446',
                        }}
                    >
                        <h3>
                            <strong>VALID CERTIFICATES</strong>
                        </h3>
                    </Col>
                    <br />
                    <Container>
                        <div>
                            <CertificationsReactTable
                                certifications={this.props.validcertifications}
                                mode={this.props.mode}
                                getDataFunc={this.props.getDataValid}
                                tablePages={this.props.validTotalPages}
                                totalNum={this.props.validTotalItems}
                                loading={this.props.loading}
                            />
                        </div>
                    </Container>
                    <br />
                    <Col
                        xs={6}
                        className="d-flex"
                        style={{
                            color: '#317446',
                        }}
                    >
                        <h3>
                            <strong>EXPIRED CERTIFICATES</strong>
                        </h3>
                    </Col>
                    <br />
                    <Container>
                        <div>
                            <CertificationsReactTable
                                certifications={this.props.expiredcertifications}
                                mode={this.props.mode}
                                getDataFunc={this.props.getDataExpired}
                                tablePages={this.props.expiredTotalPages}
                                totalNum={this.props.expiredTotalItems}
                                loading={this.props.loading}
                            />
                        </div>
                    </Container>
                </Col>
            </Row>
        );
    }
}

export default Certifications;
