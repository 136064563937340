import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';

import { Row, Col, Button, Input, FormGroup, Label } from 'reactstrap';
import CyderReactTable from 'cyder/table/CyderReactTable';
import Select from 'react-select';
import { searchWithActive } from 'actions/admin/auth/users/action.js';

const typeOptions = [
    { key: 'User', label: 'User', value: 'User' },
    { key: 'Staff', label: 'Staff', value: 'Staff' },
];

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.onKeywordChange = this.onKeywordChange.bind(this);
        this.search = this.search.bind(this);
        this.isActiveChange = this.isActiveChange.bind(this);

        this.state = {
            list: [],
            keyword: '',
            type: 'User',
            loading: false,
            pagination: true,
            pageSize: 10,
            pageCount: 0,
            isActive: {
                label: 'All',
                value: 2,
            },
            isActiveOptions: [
                {
                    label: 'All',
                    value: 2,
                },
                {
                    label: 'Active',
                    value: 1,
                },
                {
                    label: 'Inactive',
                    value: 0,
                },
            ],
        };
    }
    onKeywordChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    isActiveChange(e) {
        this.setState({ isActive: e });
    }
    changeType(type) {
        this.setState({ type });
    }
    search(page, pageSize, sorted, filtered, type) {
        this.setState({
            loading: true,
        });
        this.props
            .search(this.state.keyword, type ? type : this.state.type, this.state.isActive.value, page, pageSize, sorted, filtered)
            .then(res => {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: res.totalNoOfPages,
                    totalNum: res.totalNoOfItems,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    render() {
        const { history } = this.props;
        const columns = this.state.type === 'Staff' ? getColumnStaff() : getColumnUser();

        return (
            <div>
                <Row className="mb-2">
                    <Col xs={12}>
                        <div className="form-inline">
                            <Input
                                type="text"
                                placeholder="Keyword"
                                value={this.state.keyword}
                                onChange={this.onKeywordChange}
                                onKeyDown={e => {
                                    if (e.keyCode === 13) this.table.fireFetchData();
                                }}
                            />
                            <div style={{ width: '150px', marginLeft: '10px' }}>
                                <Select
                                    style={{ width: '150px' }}
                                    autosize={false}
                                    options={this.state.isActiveOptions}
                                    value={this.state.isActive}
                                    clearable={true}
                                    onChange={this.isActiveChange}
                                    isMulti={false}
                                    name="selected-state"
                                    disabled={false}
                                    searchable={true}
                                />
                            </div>
                            <Button className="ml-2 align-middle primary-btn-style" onClick={() => this.table.fireFetchData()}>
                                <i className="material-icons align-text-bottom">search</i>
                                &nbsp;Search
                            </Button>
                            <FormGroup className="mb-2 ml-2 mr-sm-2 mb-sm-0">
                                {typeOptions.map((item, i) => (
                                    <Label key={i}>
                                        <Input
                                            key={item.key}
                                            name="type"
                                            type="radio"
                                            value={item.value}
                                            checked={item.value === this.state.type}
                                            onChange={() => {
                                                this.changeType(item.value);
                                                // this.table.fireFetchData();
                                                this.search(0, 10, null, null, item.value);
                                            }}
                                        />
                                        {item.label}
                                    </Label>
                                ))}
                            </FormGroup>
                            <div>
                                <Button
                                    className="ml-1 mr-1 align-middle primary-btn-style"
                                    onClick={() => history.push('/admin/auth/users/0', this.state)}
                                >
                                    <i className="material-icons align-text-bottom">add</i>
                                    &nbsp;Add
                                </Button>
                                {/*
                                <Link to="/admin/auth/users/0">
                                </Link>
                                */}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            manual
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            totalNum={this.state.totalNum}
                            ref={instance => {
                                if (instance) this.table = instance.table;
                            }}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const getColumnStaff = () => {
    return [
        {
            Header: 'Username',
            accessor: 'username',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'First Name',
            accessor: 'firstName',
            Cell: props => {
                if (!props.value || props.value === 'null') return '';
                return props.value;
            },
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
            Cell: props => {
                if (!props.value || props.value === 'null') return '';
                return props.value;
            },
        },
        {
            Header: 'Active',
            accessor: 'isActive',
            maxWidth: '50px',
            Cell: props => {
                if (props.value === 1) return `Yes`;
                return `No`;
            },
        },
        {
            Header: 'Super User',
            accessor: 'isSuperuser',
            maxWidth: '50px',
            Cell: props => {
                if (props.value === 1) return `Yes`;
                return `No`;
            },
        },
        {
            Header: 'Cert Admin',
            accessor: 'isCertAdmin',
            maxWidth: '50px',
            Cell: props => {
                if (props.value === 1) return `Yes`;
                return `No`;
            },
        },
        {
            Header: 'Cert Manager',
            accessor: 'isCertManager',
            maxWidth: '50px',
            Cell: props => {
                if (props.value === 1) return `Yes`;
                return `No`;
            },
        },
        {
            Header: 'Event Admin',
            accessor: 'isEventAdmin',
            maxWidth: '50px',
            Cell: props => {
                if (props.value === 1) return `Yes`;
                return `No`;
            },
        },
        {
            Header: 'Membership Admin',
            accessor: 'isMembershipAdmin',
            maxWidth: '50px',
            Cell: props => {
                if (props.value === 1) return `Yes`;
                return `No`;
            },
        },
        {
            Header: 'Mailing List Admin',
            accessor: 'isMailingListAdmin',
            maxWidth: '50px',
            Cell: props => {
                if (props.value === 1) return `Yes`;
                return `No`;
            },
        },
        {
            Header: '',
            accessor: 'id',
            maxWidth: 50,
            sortable: false,
            Cell: props => {
                return (
                    <Link to={'/admin/auth/users/' + props.value}>
                        <Button className="primary-btn-style">
                            <i className="mr-1 material-icons align-text-bottom ">edit</i>
                        </Button>
                    </Link>
                );
            },
        },
    ];
};

const getColumnUser = () => {
    return [
        {
            Header: 'Username',
            accessor: 'username',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'First Name',
            accessor: 'firstName',
            Cell: props => {
                if (!props.value || props.value === 'null') return '';
                return props.value;
            },
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
            Cell: props => {
                if (!props.value || props.value === 'null') return '';
                return props.value;
            },
        },
        {
            Header: 'Active',
            accessor: 'isActive',
            maxWidth: '50px',
            Cell: props => {
                if (props.value === 1) return `Yes`;
                return `No`;
            },
        },
        {
            Header: '',
            accessor: 'id',
            maxWidth: 50,
            sortable: false,
            Cell: props => {
                return (
                    <Link to={'/admin/auth/users/' + props.value}>
                        <Button className="primary-btn-style">
                            <i className="mr-1 material-icons align-text-bottom ">edit</i>
                        </Button>
                    </Link>
                );
            },
        },
    ];
};

const mapStateToProps = ownProps => {
    return {
        ownProps,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, type, isActive, page, pageSize, sorted, filtered) => {
            return dispatch(searchWithActive(keyword, type, isActive, page, pageSize, sorted, filtered));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoredLayout(ListPage));
