import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import history from '../../../../history';

import { MEMBER_HOME_SET_LOADING_MEMBERSHIPINFO_STATE } from '../../../../actions/constants/actionTypesMemberHome';

import { Row, Button } from 'reactstrap';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import DashboardWidget from 'cyder/widget/DashboardWidget';
import OMRPanel from 'pages/member/membership/new/components/OMRPanel';
import MembershipInformation from 'pages/member/home/components/MembershipInformation';
import MembershipApplicationInformation from 'pages/member/home/components/MembershipApplicationInformation';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DashboardWidgetComponent from 'pages/member/home/components/DashboardWidgetComponent';

import { memberHomePanelMembershipGetInfo, memberHomePanelMembershipApplicationGetInfo } from 'actions/member/home/memberHomeAction';
import { memberMembershipApplicationGetCompanyInfo } from 'actions/member/company/memberCompanyAction';
import { memberGetTeamMembers } from 'actions/shared/teamManagementAction';

import { sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';

class MembershipDashboardWidget extends React.Component {
    _isMounted = true;
    state = {
        isOpenMembershipInformation: true,
        isUpgradeMembershipShown: false,
        companyInfo: {},
    };
    async componentDidMount() {
        await this.props.setLoading(true);
        await this.props.getMembershipInfo();
        await this.props.getMembershipApplicationInfo();
        await this.getCompanyInfo();
        await this.props.getTeamMembers();
        await this.props.setLoading(false);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    toggleCollapse = id => {
        let state = this.state;
        state[id] = !state[id];
        this.setState(state);
    };
    updateIsUpgradeMembershipShown = value => {
        if (this.state.isUpgradeMembershipShown === value) return;
        this.setState({
            isUpgradeMembershipShown: value,
        });
    };
    shouldApplyButtonDisabled() {
        const { organisationMainCategoryId, businessAddressLine1 } = this.state.companyInfo;
        if (!organisationMainCategoryId || !businessAddressLine1) return true;
        return false;
    }
    async getCompanyInfo() {
        try {
            const companyInfoRes = await this.props.getCompanyInfo();
            const companyInfo = sanitizeStringAndObjectNullsToEmptyStrings(companyInfoRes.data || {});
            if (companyInfo && this._isMounted) {
                this.setState({
                    companyInfo,
                });
            }
        } catch (error) {
            throw error;
        }
    }
    render() {
        const { isOpenMembershipInformation } = this.state;
        const {
            memberHomeMembershipApplicationInfo,
            memberHomeMembershipInfo,
            memberInfo,
            loadingMemberhipInfo,
            teamManagement,
        } = this.props;
        // 6 months after expiry date has passed, disable renewal and start sign up
        var expiredBeforeSixMonths = true;
        if (memberInfo.expiryDate) {
            if (moment().isSameOrBefore(moment(memberInfo.expiryDate))) {
                expiredBeforeSixMonths = true;
            } else {
                expiredBeforeSixMonths = moment().isSameOrBefore(moment(memberInfo.expiryDate).add(6, 'months'));
            }
        }
        let hasOmr = false;
        if (teamManagement != null && teamManagement.teamMembers != null && teamManagement.teamMembers.length > 0) {
            let omrRecord = teamManagement.teamMembers.filter(element => {
                return element.officialMangementRep === 1;
            });
            if (omrRecord != null && omrRecord.length > 0) {
                hasOmr = true;
            }
        }
        return (
            <DashboardWidget
                isOpen={isOpenMembershipInformation}
                title="MEMBERSHIP"
                toggleCollapse={() => this.toggleCollapse('isOpenMembershipInformation')}
            >
                {loadingMemberhipInfo ? (
                    <LoadingSpinner />
                ) : (
                    <Row>
                        {memberInfo.id &&
                        // expiredBeforeSixMonths &&
                        (memberHomeMembershipApplicationInfo.applicationStatusId < 2 ||
                            (memberHomeMembershipApplicationInfo.applicationStatusId >= 5 &&
                                memberHomeMembershipApplicationInfo.applicationStatusId <= 8) ||
                            (memberInfo.id && !memberHomeMembershipApplicationInfo.id)) ? (
                            <MembershipInformation
                                membershipInfo={memberHomeMembershipInfo}
                                applicationInfo={memberHomeMembershipApplicationInfo}
                                isUpgradeMembershipShown={this.state.isUpgradeMembershipShown}
                                expiredBeforeSixMonths={expiredBeforeSixMonths}
                            />
                        ) : memberHomeMembershipApplicationInfo.id === null ||
                          memberHomeMembershipApplicationInfo.applicationStatus === 'Withdrawn' ||
                          memberHomeMembershipApplicationInfo.applicationStatus === 'Not Approved' ? (
                            <DashboardWidgetComponent
                                // src={'/assets/images/membership-dashboard.jpg'}
                                headerStyle={this.state.addOmr ? { marginBottom: '5px' } : null}
                                description={
                                    hasOmr
                                        ? 'No SGBC Membership record exists. Sign up now.'
                                        : 'No SGBC Membership record exists. Signing up for SGBC Membership requires Official Management Representative (OMR).'
                                }
                            >
                                {hasOmr ? (
                                    <React.Fragment>
                                        {/* {this.shouldApplyButtonDisabled() && <div>Update you company profile before membership application</div>} */}
                                        <Button
                                            className="primary-btn-style primary-btn-lg m-1"
                                            disabled={this.shouldApplyButtonDisabled()}
                                            style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                display: 'block',
                                            }}
                                            onClick={() =>
                                                expiredBeforeSixMonths
                                                    ? history.push(`/member/membership/newapplication/1`)
                                                    : history.push('/member/membership/renewapplication/1', memberInfo)
                                            }
                                        >
                                            Sign up
                                        </Button>
                                    </React.Fragment>
                                ) : !this.state.addOmr ? (
                                    <React.Fragment>
                                        <Button
                                            className="primary-btn-style primary-btn-lg m-1"
                                            disabled={this.shouldApplyButtonDisabled()}
                                            style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                display: 'block',
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    addOmr: true,
                                                });
                                            }}
                                        >
                                            Add OMR
                                        </Button>
                                        {/* {this.shouldApplyButtonDisabled() && <span><br/><br/>Update you company profile before assigning OMR</span>} */}
                                    </React.Fragment>
                                ) : (
                                    ''
                                )}
                            </DashboardWidgetComponent>
                        ) : (
                            <MembershipApplicationInformation
                                membershipInfo={memberHomeMembershipInfo}
                                membershipApplicationInfo={memberHomeMembershipApplicationInfo}
                                isUpgradeMembershipShown={this.updateIsUpgradeMembershipShown}
                            />
                        )}

                        {this.state.addOmr && (
                            <div style={{ marginLeft: '20px' }}>
                                <OMRPanel embedded />
                            </div>
                        )}
                    </Row>
                )}
            </DashboardWidget>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.memberHomeReducer,
        teamManagement: state.teamManagementReducer,
    };
};
const mapDispatchToProps = dispatch => ({
    getMembershipInfo: () => {
        return dispatch(memberHomePanelMembershipGetInfo());
    },
    getTeamMembers: () => {
        return dispatch(memberGetTeamMembers(0, 999, null, null));
    },
    getMembershipApplicationInfo: () => {
        return dispatch(memberHomePanelMembershipApplicationGetInfo());
    },
    getCompanyInfo: () => {
        return dispatch(memberMembershipApplicationGetCompanyInfo());
    },
    setLoading: loading => {
        return dispatch({
            type: MEMBER_HOME_SET_LOADING_MEMBERSHIPINFO_STATE,
            loadingState: loading,
        });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigationLayout(MembershipDashboardWidget));
