import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import { Row, Col } from 'reactstrap';
import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getCheckedInCountAnalytic } from 'actions/analytics/eventsAnalyticsAction';

class CheckedIn extends React.Component {
    _isMounted = true;
    state = {
        data: [],
    };
    componentDidMount() {
        this.props.load(this.props.eventId).then(res => {
            if (this._isMounted)
                this.setState({
                    data: res.data[0],
                });
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <AnalyticsCard title="Checked-in?" height="85">
                {this.state.data === null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <Row>
                            <Col xs="6">
                                <Row>
                                    <Col xs="3">
                                        <AnalyticsNormalText text="Yes" />
                                    </Col>
                                    <Col>
                                        <Row className="justify-content-center">
                                            <AnalyticsBigText
                                                headingSize={4}
                                                text={`${this.state.data.attendeesAttended} / ${this.state.data.attendeesAttended +
                                                    this.state.data.attendeesNotAttended}`}
                                                textColor={`#78BE20`}
                                            />
                                            <AnalyticsBigText
                                                headingSize={4}
                                                text={`(${+(Number.isNaN(this.state.data.percentage) || this.state.data.percentage === 'NaN'
                                                    ? 0
                                                    : Math.round(this.state.data.percentage))}%)`}
                                                textColor={`#78BE20`}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="6" className="jusstify-content-center">
                                <Row>
                                    <Col xs="3">
                                        <AnalyticsNormalText text="No" />
                                    </Col>
                                    <Col>
                                        <Row className="justify-content-center">
                                            <AnalyticsBigText
                                                headingSize={4}
                                                text={`${this.state.data.attendeesNotAttended}`}
                                                textColor={`#ed5565`}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: eventId => {
            return dispatch(getCheckedInCountAnalytic(eventId));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(StoredLayout(CheckedIn));
