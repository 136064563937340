import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import StoredLayout from 'layouts/StoredLayout';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { membershipRenewalAnalytics } from 'actions/analytics/memberAnalyticsAction';
import CyderDoughnut from 'pages/analytics/components/charts/CyderDoughnut';
var tinygradient = require('tinygradient');

// var gradient = tinygradient('#68C0D6', '#78BE20');
var gradient = tinygradient('#36a2eb', '#36eb87', '#ffce56', '#ff8686');

var generateGradient = step => {
    var colorsRgb = gradient.rgb(step);
    return colorsRgb;
};

class MembershipRenewalDistribution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshState: new Date().getTime(),
            legendconfig: {
                legend: {
                    onClick: null,
                },
            },
        };
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.membership.refresh) {
            this.props.load().then(res => {
                var col = generateGradient(res.data.length);
                let labels = [];
                let data = [];
                let total = 0;
                let originalTotal = 0;
                let backgroundColor = [];
                let hoverBackgroundColor = [];
                res.data.forEach((element, i) => {
                    if (element.status != 'Total') {
                        labels.push(element.status);
                        data.push(element.count);
                        total += element.count;
                        originalTotal += element.count;
                        backgroundColor.push(col[i].toHexString());
                    }
                });
                if (total <= 0) {
                    res.data.forEach((element, i) => {
                        if (element.status === 'Total') {
                            total = element.totalExpiringCurrentYear;
                        }
                    });
                }
                this.setState({
                    data,
                    backgroundColor,
                    hoverBackgroundColor,
                    labels,
                    originalTotal,
                    total,
                    centerText: total,
                    refreshState: this.props.membership.refresh,
                });
            });
        }
    }
    componentDidMount() {
        this.props.load().then(res => {
            var col = generateGradient(res.data.length);
            let labels = [];
            let data = [];
            let total = 0;
            let originalTotal = 0;
            let backgroundColor = [];
            let hoverBackgroundColor = [];
            res.data.forEach((element, i) => {
                if (element.status != 'Total') {
                    labels.push(element.status);
                    data.push(element.count);
                    originalTotal += element.count;
                    total += element.count;
                    backgroundColor.push(col[i].toHexString());
                }
            });
            if (total <= 0) {
                res.data.forEach((element, i) => {
                    if (element.status === 'Total') {
                        total = element.totalExpiringCurrentYear;
                    }
                });
            }
            this.setState({
                data,
                backgroundColor,
                hoverBackgroundColor,
                labels,
                originalTotal,
                total,
                centerText: total,
            });
        });
    }

    render() {
        return (
            <AnalyticsCard
                title={`${
                    this.state.data != null
                        ? `Memberships Expiring/Expired within ${moment(this.props.membership.startDate, 'DD/MM/YYYY').format(
                              'DD MMMM YYYY',
                          )} - ${moment(this.props.membership.endDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}`
                        : `...`
                }`}
            >
                {this.state.data === null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        {this.state.originalTotal > 0 ? (
                            <CyderDoughnut {...this.state} />
                        ) : (
                            <div>
                                <br />
                                No breakdown is available. Total expiring members {this.state.total}
                            </div>
                        )}
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        membership: state.membershipRenewalAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(membershipRenewalAnalytics());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(StoredLayout(MembershipRenewalDistribution));
