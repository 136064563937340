import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Button, FormGroup, Label } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

import { upload, loadAttachment } from 'actions/admin/misc/resources/action.js';

import { SGBC_S3_ROOT } from 'config';
const axios = require('axios').default;
const FormData = require('form-data');

class CyderAttachment extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.fileInput = React.createRef();
        this.upload = this.upload.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.state = {
            loading: false,
            attachmentId: '',
            filename: '',
            message: '',
            file: {},
            virusMessage: '',
            scanning: false,
        };
    }
    showLoading(loading) {
        if (!this._ismounted) return;
        this.setstate({
            loading,
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async componentDidMount() {
        this.showLoading(true);
        let attachmentId = this.props.attachmentId;
        let attachmentDetails = await this.props.load(attachmentId);
        let filename = this.props.filename
            ? this.props.filename
            : attachmentDetails !== null && attachmentDetails.data !== null
            ? attachmentDetails.data.filename
            : 'download';
        if (this._isMounted)
            this.setState({
                attachmentId: attachmentId === 'null' ? null : attachmentId,
                message: this.props.message || '',
                filename,
                loading: false,
            });
    }
    componentDidUpdate() {
        if (this.state.attachmentId !== null && this.state.attachmentId !== '' && this.state.attachmentId !== this.props.attachmentId) {
            this.setState({
                attachmentId: this.props.attachmentId,
                filename: this.props.filename,
            });
        }
    }
    handleFileInputChange = event => {
        this.setState({ scanning: true });
        const limit = this.props.sizeLimit || 5;
        const reader = new FileReader();
        let file = event.target.files[0];

        const files = Array.from(event.target.files);

        // Virus Scanning
        const uploaders = files.map(x => {
            const formData = new FormData();
            formData.append('file', x);

            return axios.post('https://av.sgbc.online/api/av', formData, {
                headers: { 'Content-Type': 'multipart/form-dta' },
            });
        });

        console.log('Scanning files before uploading.');

        axios
            .all(uploaders)
            .then(res => {
                console.log('Files are all clean.');
                this.setState({
                    virusMessage: '',
                    scanning: false,
                });

                reader.onload = () => {
                    file.base64 = reader.result;
                    if (file && file.size > limit * 1000000) {
                        this.setState({
                            file: null,
                            message: `File size should not be more than ${limit} MB`,
                        });
                        return;
                    }
                    this.setState({ file: file, message: '' }, () => this.upload());
                };
                // Handle cancel
                if (file.length !== 0) reader.readAsDataURL(file);
            })
            .catch(err => {
                const { viruses } = err.response.data;
                this.setState({
                    virusMessage: viruses,
                    scanning: false,
                });
            });
    };
    upload() {
        this.showLoading(true);
        var base64 = this.state.file.base64;
        var idx = base64.indexOf(`base64,`);
        base64 = base64.substr(idx + 7);
        var data = {
            attachment: base64,
            contenttype: this.state.file.type,
            filename: this.state.file.name,
            filesize: 1000,
        };
        this.props
            .upload(data)
            .then(res => {
                this.props.onUploadSuccess(res.data);
                this.showLoading(false);
                this.setState({
                    attachmentId: res.data.id,
                    filename: res.data.filename,
                });
            })
            .catch(error => {
                this.props.onUploadFail(error);
                this.showLoading(false);
                throw error;
            });
    }
    render() {
        if (this.state.loading || this.state.scanning)
            return (
                <div style={{ width: this.state.scanning ? '200px' : '20px' }}>
                    <LoadingSpinner text={this.state.scanning ? 'Virus Scanning ...' : null} />
                </div>
            );
        const { virusMessage } = this.state;
        return (
            <div>
                {!this.state.attachmentId && (
                    <div>
                        <Row>
                            <Col>
                                <FormGroup className="upload-section">
                                    {this.state.message !== null && this.state.message !== '' && (
                                        <React.Fragment>
                                            <small htmlFor="resource">{this.state.message}</small>
                                            <br />
                                            <br />
                                        </React.Fragment>
                                    )}
                                    <Button
                                        className="primary-btn-style text-bold text-capitalize mr-1"
                                        onClick={() => this.fileInput.current.click()}
                                    >
                                        Choose File and Upload
                                    </Button>
                                    &nbsp;
                                    <Label for="resource">{this.state.attachmentId ? this.state.filename : ''}</Label>
                                    <input
                                        type="file"
                                        ref={this.fileInput}
                                        disabled={this.state.loading}
                                        onChange={this.handleFileInputChange}
                                        style={{ display: 'none' }}
                                    />
                                    <div style={{ width: '150px', marginTop: '0px' }}>
                                        <span>
                                            Maximum file size is {this.props.sizeLimit || 5}
                                            MB
                                        </span>
                                    </div>
                                    {virusMessage && (
                                        <p
                                            style={{ color: 'red' }}
                                            className="mt-2 form-text"
                                        >{`[Unable to upload] Virus scan found '${virusMessage}' in one of the files.`}</p>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                )}
                {this.state.attachmentId && (
                    <div>
                        <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.attachmentId}`}>
                            <u>{this.state.filename}</u>
                        </a>
                        &nbsp;
                        <i
                            className="material-icons"
                            style={{ cursor: 'pointer' }}
                            onClick={e => {
                                this.setState({
                                    attachmentId: '',
                                });
                            }}
                        >
                            edit
                        </i>
                        &nbsp;
                        {this.props.removeFile && (
                            <i
                                className="material-icons"
                                style={{ cursor: 'pointer' }}
                                onClick={e => {
                                    this.props.removeFile(this.props.attachment);
                                    this.setState({ attachmentId: '' });
                                }}
                            >
                                delete
                            </i>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {
        upload: data => {
            return dispatch(upload(data));
        },
        load: id => {
            return dispatch(loadAttachment(id));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CyderAttachment);
