/**
 * Assign approver panel
 *
 * Author: Fernando
 */
import React from 'react';
import ButtonIcon from 'cyder/button/ButtonIcon';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';

const selectState = {
    step: 0,
    disabled: false,
    searchable: true,
    selectValue: '',
    clearable: true,
};

const AssignApproverPanel = ({
    sendForApproval,
    selectedPeer,
    peers,
    onSelectedPeerChange,
    selectedManager,
    managers,
    onSelectedManagerChange,
}) => (
    <Row>
        <Col>
            <Row style={{ paddingBottom: '10px' }}>
                <Col>
                    <h5 className="text-bold">Application Approval</h5>
                </Col>
            </Row>
            <Row style={{ paddingBottom: '10px' }}>
                <Col>
                    <label className="field-header">1. Please choose officer for review</label>
                </Col>
            </Row>
            <Row style={{ paddingBottom: '10px' }}>
                <Col xs={4}>
                    <Select
                        autosize={false}
                        placeholder=""
                        autofocus
                        options={peers}
                        simpleValue
                        clearable={selectState.clearable}
                        name="selected-state"
                        disabled={selectState.disabled}
                        value={selectedPeer}
                        onChange={onSelectedPeerChange}
                        searchable={selectState.searchable}
                    />
                </Col>
            </Row>

            <Row style={{ paddingBottom: '10px' }}>
                <Col>
                    <label className="field-header">2. Please choose management for review</label>
                </Col>
            </Row>
            <Row style={{ paddingBottom: '10px' }}>
                <Col xs={4}>
                    <Select
                        autosize={false}
                        placeholder=""
                        autofocus
                        options={managers}
                        simpleValue
                        clearable={selectState.clearable}
                        name="selected-state"
                        disabled={selectState.disabled}
                        value={selectedManager}
                        onChange={onSelectedManagerChange}
                        searchable={selectState.searchable}
                    />
                </Col>
            </Row>

            <Row style={{ paddingBottom: '10px' }}>
                <Col>
                    <ButtonIcon color={'success'} onClick={sendForApproval} icon={'check'} label={'Send for Approval'} />
                </Col>
            </Row>
        </Col>
    </Row>
);

export default AssignApproverPanel;
