import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { SGBC_S3_HOST } from 'config';
import { Row } from 'reactstrap';
import { printReceipt } from 'actions/shared/eventRsvpAction.js';
import LoadingSpinner from '../../../cyder/loadingSpinner/LoadingSpinner';

class EventReceiptPage extends React.Component {
    constructor(props) {
        super(props);
        this.printReceipt = this.printReceipt.bind(this);
        this.state = {
            receiptUrl: '',
            loadingRegistration: false,
        };
    }
    async printReceipt() {
        try {
            this.setState({
                loadPrintingReceipt: true,
            });
            const res = await this.props.printReceipt(this.props.match.params.paymentid);
            // window.open(SGBC_S3_HOST + '/temp/' + res.data.file);
            this.setState({
                receiptUrl: SGBC_S3_HOST + '/temp/' + res.data.file,
            });
        } catch (error) {
            //this.showModal('Error', 'Receipt cannot be printed', null, false, true);
            throw error;
        } finally {
            this.setState({
                loadPrintingReceipt: false,
            });
        }
    }
    componentDidMount() {
        this.printReceipt();
    }
    render() {
        return (
            <div className="page-widget">
                {this.state.loadPrintingReceipt ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <Row className="p-2">
                            <h3>Payment Receipt</h3>
                        </Row>
                        <Row>
                            <iframe width="100%" height="500px" src={this.state.receiptUrl} />
                        </Row>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        printReceipt: id => {
            return dispatch(printReceipt(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoredLayout(EventReceiptPage));
