import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';
import ButtonIcon from 'cyder/button/ButtonIcon';
import { Row, Button, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import DashboardWidgetComponent from '../DashboardWidgetComponent';
import CertificatesApplication from './CertificatesApplication';
import CertificatesCertificates from './CertificatesCertificates';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import {
    memberHomeCertificatesGetApplications,
    memberHomeCertificatesGetCertificates,
    memberHomeCertificatesGetExpiringCertificates,
    memberHomeCertificatesGetCertificateApplication,
} from 'actions/member/home/memberHomeCertificatesAction';
import { printCert, downloadCertificate } from 'actions/shared/certificationAction';
import { memberMembershipApplicationGetCompanyInfo } from 'actions/member/company/memberCompanyAction';
import { SGBC_DISABLED_CERT, SGBC_OVERRIDE_ENABLED_CERT_COMPANIES } from 'config.js';
import { b64toBlob, actionExecutor, sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';

import 'css/ui-elements/buttons.css';
import 'css/cyder/table/dashboard-table.css';

const isIE = /*@cc_on!@*/ false || !!document.documentMode;

const ConditionalWrapper = ({ memberCertData, loading, children }) => {
    if (loading) return <LoadingSpinner />;
    else
        return memberCertData.length === 0 ? (
            <DashboardWidgetComponent
                // src="/assets/images/certificate-dashboard.jpg"
                headerStyle={{ marginBottom: '2em' }}
                description="No SGBC Certification records exist."
            >
                <div
                    style={{
                        // position: 'absolute',
                        bottom: 0,
                        width: '100%',
                    }}
                >
                    {children}
                </div>
            </DashboardWidgetComponent>
        ) : (
            <Row className="justify-content-center mb-4">{children}</Row>
        );
};

class Certificates extends React.Component {
    _isMounted = true;
    state = {
        loading: false,
        loadingAll: false,
        clickable: true,
        type: 'product',
        companyInfo: {},
        applications: [],
        certificates: [],
        certificateApplications: [],
        productCertRenewable: false,
        serviceCertRenewable: false,
    };
    componentDidMount() {
        window.scrollTo(0, 0);

        let disabledCert = SGBC_DISABLED_CERT;
        if (
            this.props.profile != null &&
            this.props.profile.profile != null &&
            this.props.profile.profile.company_name != null &&
            SGBC_OVERRIDE_ENABLED_CERT_COMPANIES.includes(this.props.profile.profile.company_name.toLowerCase())
        ) {
            disabledCert = false;
        }
        this.setState({
            disabledCert,
            activeTab: disabledCert ? 'productCert' : 'productAppl',
        });

        this.getRelevantData();

        // this.getCompanyInfo();
    }
    async getRelevantData() {
        const { getCertificateApplication } = this.props;
        try {
            this.setState({
                loadingAll: true,
            });
            const { data: certificateApplicationsData } = await getCertificateApplication();

            const { data: companyInfoDataTemp } = await this.props.getMembershipInfo();
            const { data: expiringCerts } = await this.props.getExpiringCertificates();

            let serviceCertRenewable = false;
            let productCertRenewable = false;
            if (expiringCerts != null && expiringCerts.length > 0) {
                expiringCerts.forEach(element => {
                    // 6 months before expiration and 6 months after expiration able to renew
                    if (-180 < element.expiredInDays && element.expiredIndays < 180) {
                        if (element.certType == 'Service') {
                            serviceCertRenewable = true;
                        } else {
                            productCertRenewable = true;
                        }
                    }
                });
            }
            const companyInfoData = sanitizeStringAndObjectNullsToEmptyStrings(companyInfoDataTemp || {});

            if (this._isMounted) {
                this.setState({
                    certificateApplications: certificateApplicationsData,
                    companyInfo: companyInfoData,
                    serviceCertRenewable,
                    productCertRenewable,
                });
            }
            this.getApplications();
            // this.toggleTab('productAppl');
        } catch (error) {
            throw error;
        } finally {
            this.setState({
                loadingAll: false,
            });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoading = show => {
        this.setState({
            loading: show,
        });
    };
    isClickable(clickable) {
        this.setState({
            clickable,
        });
    }
    getApplications = async () => {
        const { activeTab } = this.state;
        const { getApplications } = this.props;
        const type = activeTab.includes('product') ? 'product' : 'service';
        try {
            this.showLoading(true);
            const { data } = await getApplications(type);
            if (this._isMounted)
                this.setState({
                    type,
                    applications: data,
                });
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    };
    getCertificates = async (page, pageSize) => {
        const { activeTab } = this.state;
        const { getCertificates } = this.props;
        const type = activeTab.includes('product') ? 'product' : 'service';
        try {
            this.showLoading(true);
            const { data } = await getCertificates(page, pageSize, type);
            if (this._isMounted)
                this.setState({
                    type,
                    certificates: data, //no more res is passed
                });
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    };
    printCert = async (certificateId, type) => {
        this.showLoading(true);
        this.isClickable(false);
        try {
            this.props.downloadCertificate(certificateId);
            // const blob = b64toBlob(res.data, 'application/pdf');
            // const blobUrl = URL.createObjectURL(blob);
            // window.open(blobUrl);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.isClickable(true);
        }
    };
    shouldApplyButtonDisabled(hasOmr) {
        const { organisationMainCategoryId, officialManagementRepresentativeId, businessAddressLine1 } = this.state.companyInfo;
        if (!organisationMainCategoryId || !businessAddressLine1 || !hasOmr) return true;
        return false;
    }
    // TABS NAVIGATION
    toggleTab = tab => {
        const { activeTab, clickable } = this.state;
        if (activeTab !== tab && clickable) {
            this.setState(
                {
                    activeTab: tab,
                },
                () => (tab.includes('Appl') ? this.getApplications() : this.getCertificates()),
            );
        }
    };
    renderNavItem = navItems => {
        return navItems.map(navItem => {
            const { key, text } = navItem;
            return (
                <NavItem key={key}>
                    <NavLink
                        className={this.state.activeTab === key ? 'active' : null}
                        onClick={() => {
                            this.toggleTab(key);
                        }}
                    >
                        {text}
                    </NavLink>
                </NavItem>
            );
        });
    };
    render() {
        const navsAll = [
            {
                key: 'productAppl',
                text: 'Product Applications',
            },
            {
                key: 'serviceAppl',
                text: 'Services Applications',
            },
            {
                key: 'productCert',
                text: 'Product Certificates',
            },
            {
                key: 'serviceCert',
                text: 'Services Certificates',
            },
        ];
        const navsOnlyCert = [
            {
                key: 'productCert',
                text: 'Product Certificates',
            },
            {
                key: 'serviceCert',
                text: 'Services Certificates',
            },
        ];
        const navs = this.state.disabledCert ? navsOnlyCert : navsAll;
        const { activeTab, certificateApplications } = this.state;
        const { state, getApplications, getCertificates, printCert } = this;
        const { memberHomeCerts, teamManagement } = this.props;

        let hasOmr = false;
        if (teamManagement != null && teamManagement.teamMembers != null && teamManagement.teamMembers.length > 0) {
            let omrRecord = teamManagement.teamMembers.filter(element => {
                return element.officialMangementRep === 1;
            });
            if (omrRecord != null && omrRecord.length > 0) {
                hasOmr = true;
            }
        }

        const paddingLeft = isIE ? '100px' : '10px';

        return (
            <React.Fragment>
                {!this.state.disabledCert && (
                    <ConditionalWrapper memberCertData={memberHomeCerts.concat(certificateApplications)} loading={this.state.loadingAll}>
                        <div className="text-center">
                            <Button
                                className="primary-btn-style primary-btn-lg m-1"
                                disabled={this.shouldApplyButtonDisabled(hasOmr)}
                                onClick={() => history.push(`/member/certification/product/newapplication`)}
                            >
                                <i className="fas fa-folder-plus" />
                                &nbsp; Apply Product Certificate
                            </Button>
                            <Button
                                className="primary-btn-style primary-btn-lg m-1"
                                disabled={this.shouldApplyButtonDisabled(hasOmr)}
                                onClick={() => history.push(`/member/certification/service/newapplication`)}
                            >
                                <i className="fas fa-folder-plus" />
                                &nbsp; Apply Services Certificate&nbsp;
                            </Button>
                        </div>

                        {(this.state.productCertRenewable || this.state.serviceCertRenewable) && (
                            <div className="text-center">
                                {this.state.productCertRenewable && (
                                    <Button
                                        className="m-1"
                                        style={{
                                            paddingLeft: '18px',
                                            paddingRight: '18px',
                                            backgroundColor: '#e1ab4c',
                                            borderColor: '#e1ab4c',
                                            fontWeight: 'bold',
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={() => history.push(`/member/certification/product/certificates`)}
                                    >
                                        <i className="fas fa-folder-plus" />
                                        &nbsp; Renew Product Certificate
                                    </Button>
                                )}
                                {this.state.serviceCertRenewable && (
                                    <Button
                                        className="m-1"
                                        style={{
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            backgroundColor: '#e1ab4c',
                                            borderColor: '#e1ab4c',
                                            fontWeight: 'bold',
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={() => history.push(`/member/certification/service/certificates`)}
                                    >
                                        <i className="fas fa-folder-plus" />
                                        &nbsp; Renew Services Certificate&nbsp;
                                    </Button>
                                )}
                            </div>
                        )}
                    </ConditionalWrapper>
                )}
                {((certificateApplications != null && certificateApplications.length > 0) ||
                    (memberHomeCerts != null && memberHomeCerts.length > 0)) && (
                    <div className="nav-tabs-vertical-resources nav-tabs-primary">
                        <Nav tabs className="nav-vertical">
                            {this.renderNavItem(navs)}
                        </Nav>
                        <TabContent activeTab={activeTab} style={{ paddingLeft }}>
                            {!this.state.disabledCert && (
                                <TabPane tabId="productAppl">
                                    {state.activeTab == 'productAppl' && (
                                        <CertificatesApplication
                                            state={state}
                                            loading={this.state.loading}
                                            tabId="productAppl"
                                            getDataFunc={getApplications}
                                        />
                                    )}
                                </TabPane>
                            )}
                            {!this.state.disabledCert && (
                                <TabPane tabId="serviceAppl">
                                    {state.activeTab == 'serviceAppl' && (
                                        <CertificatesApplication
                                            state={state}
                                            loading={this.state.loading}
                                            tabId="serviceAppl"
                                            getDataFunc={getApplications}
                                        />
                                    )}
                                </TabPane>
                            )}
                            <TabPane tabId="productCert">
                                {state.activeTab == 'productCert' && (
                                    <CertificatesCertificates
                                        state={state}
                                        tabId="productCert"
                                        getDataFunc={getCertificates}
                                        printCert={printCert}
                                    />
                                )}
                            </TabPane>
                            <TabPane tabId="serviceCert">
                                {state.activeTab == 'serviceCert' && (
                                    <CertificatesCertificates
                                        state={state}
                                        tabId="serviceCert"
                                        getDataFunc={getCertificates}
                                        printCert={printCert}
                                    />
                                )}
                            </TabPane>
                        </TabContent>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        profile: state.profile,
        certificates: state.member.membershipCertificates,
        membershipInfo: state.member.membershipInfo,
        teamManagement: state.teamManagementReducer,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        applyProductCertification: () => {
            history.replace('/member/certification/product/newapplication');
        },
        applyServiceCertification: () => {
            history.replace('/member/certification/service/newapplication');
        },
        getApplications: type => {
            return dispatch(memberHomeCertificatesGetApplications(type));
        },
        getCertificates: (page, pageSize, type) => {
            return dispatch(memberHomeCertificatesGetCertificates(0, 3, type)); //already adding 1 in the finall call
        },
        getExpiringCertificates: () => {
            return dispatch(memberHomeCertificatesGetExpiringCertificates());
        },
        getCertificateApplication: () => {
            return dispatch(memberHomeCertificatesGetCertificateApplication());
        },
        getMembershipInfo: () => {
            return dispatch(memberMembershipApplicationGetCompanyInfo());
        },
        printCert: (certificateId, type) => {
            return dispatch(printCert(certificateId, type));
        },
        downloadCertificate: certificateId => {
            return dispatch(downloadCertificate(certificateId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Certificates);
