/**
 * React Routes
 *
 * Author: Fernando
 */

import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

// PrivateRoute
import PrivateRoute from './cyder/common/PrivateRoute';

//pages
// in the future, follow the format of <module(plural)> <Purpose> Page
import Login from './pages/login';
import ChangePassword from './pages/changepassword/ChangePassword';
import ForgotPassword from './pages/forgotpassword/ForgotPassword';
import CreateAccountPage from './pages/createaccount/CreateAccountPage';
import ErrorPage from './pages/error-page';
import ResetPassword from './pages/forgotpassword/ResetPassword';

// Events
import EventsRsvpPage from './pages/events/EventsRsvpPage';

// Non Member
// ...
// Help
import Help from './pages/help/Help';
import TFA from './pages/tfa/TFA';

const RoutesOther = () => (
    <Switch>
        <Route
            path="/admin"
            render={() => {
                return (
                    <Redirect
                        to={{
                            pathname: '/',
                        }}
                    />
                );
            }}
        />
        <Route
            path="/staff"
            render={() => {
                return (
                    <Redirect
                        to={{
                            pathname: '/',
                        }}
                    />
                );
            }}
        />
        <Route
            path="/member"
            render={() => {
                return (
                    <Redirect
                        to={{
                            pathname: '/',
                        }}
                    />
                );
            }}
        />

        <Route exact path="/login" component={Login} />
        <Route exact path="/changepassword" component={ChangePassword} />
        <Route exact path="/changepassword/:token" component={ChangePassword} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/resetpassword" component={ResetPassword} />
        <Route exact path="/createaccount" component={CreateAccountPage} />

        <Route exact path="/tfa" component={TFA} />
        {/* events */}
        <Route exact path="/event/rsvp/:eventid/ok" component={EventsRsvpPage} />
        <Route exact path="/event/rsvp/:eventid/form" component={EventsRsvpPage} />
        <Route exact path="/event/rsvp/:eventid/tix" component={EventsRsvpPage} />
        <Route exact path="/event/rsvp/:eventid" component={EventsRsvpPage} />

        {/* Help page */}
        <PrivateRoute exact path="/help" component={Help} />
        {/* 404 page */}
        <PrivateRoute path="/" component={ErrorPage} />
    </Switch>
);

export default RoutesOther;
