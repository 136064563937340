import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import AnalyticsToolbar from 'pages/analytics/components/AnalyticsToolbar';
import ButtonIcon from 'cyder/button/ButtonIcon';
import Select from 'react-select';
import { Row, Col } from 'reactstrap';
import { Container } from 'semantic-ui-react';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';

const AnalyticsButton = ({ btnProps, colStyle, onClick, label, check, icon }) => {
    let buttonColor = null;
    let fontColor = null;
    if (check) {
        buttonColor = 'grey';
        fontColor = 'white';
    }
    // console.log("buttonColor: ", buttonColor, ", check: ", check);
    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col style={colStyle}>
                <ButtonIcon icon={icon} label={label} onClick={onClick} {...btnProps} buttonColor={buttonColor} fontColor={fontColor} />
            </Col>
        </Row>
    );
};

var buttonStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
};

class EventsAnalyticsToolbar extends React.Component {
    constructor(props) {
        super(props);

        this.filterAll = this.filterAll.bind(this);
        this.filterPublic = this.filterPublic.bind(this);
        this.filterPrivate = this.filterPrivate.bind(this);
        this.filterMembersOnly = this.filterMembersOnly.bind(this);
        this.searchByDates = this.searchByDates.bind(this);
        this.filterYear = this.filterYear.bind(this);

        this.state = {
            years: [
                { value: '2015', label: '2015' },
                { value: '2016', label: '2016' },
                { value: '2017', label: '2017' },
                { value: '2018', label: '2018' },
                { value: '2019', label: '2019' },
                { value: '2020', label: '2020' },
            ],
            selectedYear: null,
        };
    }
    searchByDates() {
        // console.log("searchByDates");
        this.props.refresh();
    }
    filterAll() {
        // console.log("filterAll");
        this.props.setFilter('all');
        this.props.refresh();
    }
    filterPublic() {
        // console.log("filterPublic");
        this.props.setFilter('public');
        console.log('setiing filter as public');
        this.props.refresh();
    }
    filterPrivate() {
        // console.log("filterPrivate");
        this.props.setFilter('private');
        this.props.refresh();
    }
    filterMembersOnly() {
        // console.log("filterMembersOnly");
        this.props.setFilter('member');
        this.props.refresh();
    }
    filterYear(e) {
        this.props.setStartDate(moment('01/01/' + e.value, 'DD/MM/YYYY'));
        this.props.setEndDate(moment('31/12/' + e.value, 'DD/MM/YYYY'));
        this.setState({
            selectedYear: e,
        });
        this.props.refresh();
    }
    componentDidMount() {
        let currentYear = moment().format('YYYY');
        this.props.setStartDate(moment('01/01/' + currentYear, 'DD/MM/YYYY'));
        this.props.setEndDate(moment('31/12/' + currentYear, 'DD/MM/YYYY'));
    }

    render() {
        return (
            <AnalyticsToolbar title={`Events Analytics`}>
                <React.Fragment>
                    <Container>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs="auto" style={{ paddingTop: '5px' }}>
                                        Filter
                                    </Col>
                                    <Col xs="auto" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <AnalyticsButton
                                            colStyle={buttonStyle}
                                            onClick={this.filterAll}
                                            check={this.props.events.filter == 'all'}
                                            label="All"
                                        />
                                    </Col>
                                    <Col xs="auto" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <AnalyticsButton
                                            colStyle={buttonStyle}
                                            onClick={this.filterPublic}
                                            check={this.props.events.filter == 'public'}
                                            label="Public"
                                        />
                                    </Col>
                                    <Col xs="auto" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <AnalyticsButton
                                            colStyle={buttonStyle}
                                            onClick={this.filterPrivate}
                                            check={this.props.events.filter == 'private'}
                                            label="Private"
                                        />
                                    </Col>
                                    <Col xs="auto" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <AnalyticsButton
                                            colStyle={buttonStyle}
                                            onClick={this.filterMembersOnly}
                                            check={this.props.events.filter == 'member'}
                                            label="Members Only"
                                        />
                                    </Col>
                                    <Col xs="auto" style={{ paddingTop: '5px', paddingLeft: '50px', paddingRight: '2px' }}>
                                        Choose by year
                                    </Col>
                                    <Col xs="1" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <Select
                                            autosize={false}
                                            options={this.state.years}
                                            value={this.state.selectedYear}
                                            clearable={true}
                                            onChange={this.filterYear}
                                            name="selected-state"
                                            disabled={false}
                                            searchable={true}
                                        />
                                    </Col>
                                    <Col xs="auto" style={{ paddingTop: '5px', paddingLeft: '20px', paddingRight: '2px' }}>
                                        Start*
                                    </Col>
                                    <Col xs="auto" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <CyderDatePicker
                                            value={this.props.events.startDate}
                                            onChange={this.props.setStartDate}
                                            closeOnSelect={true}
                                            datetimeClassName="mr-2"
                                            dateFormat="DD/MM/YYYY"
                                        />
                                    </Col>
                                    <Col xs="auto" style={{ paddingTop: '5px', paddingLeft: '20px', paddingRight: '2px' }}>
                                        End*
                                    </Col>
                                    <Col xs="auto" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <CyderDatePicker
                                            value={this.props.events.endDate}
                                            onChange={this.props.setEndDate}
                                            closeOnSelect={true}
                                            datetimeClassName="mr-2"
                                            dateFormat="DD/MM/YYYY"
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <AnalyticsButton
                                            colStyle={buttonStyle}
                                            onClick={this.searchByDates}
                                            color={`primary`}
                                            icon="arrow_forward"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            </AnalyticsToolbar>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        events: state.eventsAnalyticsReducer,
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setStartDate: e => {
            try {
                let dt = e.format('DD/MM/YYYY');
                return dispatch({
                    type: 'EVENTS_ANALYTICS_SET_START_DATE',
                    start: dt,
                });
            } catch (error) {}
        },
        setEndDate: e => {
            try {
                let dt = e.format('DD/MM/YYYY');
                return dispatch({
                    type: 'EVENTS_ANALYTICS_SET_END_DATE',
                    end: dt,
                });
            } catch (error) {}
        },
        setFilter: filter => {
            try {
                return dispatch({
                    type: 'EVENTS_ANALYTICS_SET_FILTER',
                    filter,
                });
            } catch (error) {}
        },
        refresh: () => {
            try {
                return dispatch({
                    type: 'EVENTS_ANALYTICS_REFRESH',
                });
            } catch (error) {}
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(StoredLayout(EventsAnalyticsToolbar));
