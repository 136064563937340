/**
 * Template List panel
 *
 * Author: Fernando
 */
import React from 'react';
import { Link } from 'react-router-dom';

import { FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import CyderReactTable from 'cyder/table/CyderReactTable';
import Select from 'react-select';
import YesNoIcon from 'cyder/media/YesNoIcon';

const typeOptions = [
    { key: 'product', label: 'Product', value: 'product' },
    { key: 'service', label: 'Services', value: 'service' },
];

const TemplateListPanel = props => {
    const {
        typeState,
        templates,
        tablePages,
        totalNum,
        loading,
        getDataFunc,
        handleInputChange,
        onFilterChange,
        productCategories,
        serviceCategories,
        onCategorySelected,
        selectedCategory,
        exportAll,
        onStatusChange,
        statuses,
        status,
    } = props;

    const type = typeState === 'all' ? 'product' : typeState;
    const columns = [
        {
            Header: 'Category (L1)',
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
            sortable: true,
            accessor: 'category',
        },
        {
            Header: 'Sub Category (L2)',
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
            sortable: true,
            accessor: 'subCategory',
        },
        {
            Header: 'Type (L3)',
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
            sortable: true,
            accessor: 'productType',
        },
        {
            Header: 'Code',
            sortable: true,
            accessor: 'title',
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
        },
        {
            Header: 'Status',
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
            accessor: 'active',
            sortable: true,
            Cell: props => <YesNoIcon negativeIcon="cancel" val={props.value} />,
        },
        {
            Header: '',
            sortable: false,
            accessor: 'id',
            maxWidth: 100,
            Cell: props => {
                return (
                    <Link to={`/staff/certification/assessment/scoresheet-criteria/new/${type}/${props.value}`}>
                        <Button className="primary-btn-style">
                            <i className="mr-1 material-icons align-text-bottom ">edit</i>
                            Open
                        </Button>
                    </Link>
                );
            },
        },
    ];

    var allCount = 0;
    if (type === 'product') {
        if (productCategories != null && productCategories.data != null) {
            productCategories.data.forEach(element => {
                // Exclude uncategorized
                allCount += element.id === 0 ? 0 : element.criteriaCount;
            });
        }
    } else {
        if (serviceCategories != null && serviceCategories.data != null) {
            serviceCategories.data.forEach(element => {
                // Exclude uncategorized
                allCount += element.id === 0 ? 0 : element.criteriaCount;
            });
        }
    }

    return (
        <div>
            <Row className="mb-2">
                <Col xs={12}>
                    <div className="form-inline">
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <Input
                                type="text"
                                name="text"
                                placeholder="Keyword"
                                data-value-key="keyword"
                                onKeyDown={e => {
                                    if (e.keyCode === 13) getDataFunc();
                                }}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            {typeOptions.map((item, i) => (
                                <Label key={i}>
                                    <Input
                                        key={item.key}
                                        name="type"
                                        type="radio"
                                        value={item.value || typeState}
                                        checked={item.value === typeState}
                                        onChange={() => onFilterChange(item.value)}
                                    />
                                    {item.label}
                                </Label>
                            ))}
                        </FormGroup>
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <div style={{ width: '180px' }}>
                                <Select
                                    autosize={false}
                                    options={statuses}
                                    value={status}
                                    clearable={true}
                                    onChange={onStatusChange}
                                    isMulti={false}
                                    name="selected-state"
                                    disabled={false}
                                    searchable={true}
                                />
                            </div>
                        </FormGroup>
                        <Button className="primary-btn-style" onClick={() => getDataFunc()}>
                            <i className="mr-1 material-icons align-text-bottom ">search</i>
                            Search
                        </Button>
                        <Button className="primary-btn-style" style={{ marginLeft: '5px' }} onClick={() => exportAll()}>
                            <i className="mr-1 material-icons align-text-bottom ">print</i>
                            Print
                        </Button>
                        <Link to={`/staff/certification/assessment/scoresheet-criteria/new/${type}`}>
                            <Button className="primary-btn-style" style={{ marginLeft: '5px' }}>
                                <i className="mr-1 material-icons align-text-bottom">add</i>
                                Add {type === 'product' ? 'Product' : 'Services'} Criteria
                            </Button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col xs={12}>
                    <div className="form-inline">
                        <Button
                            style={{ marginBottom: '5px' }}
                            className={`${type === 'product' ? '' : 'mr-2'} ${
                                !selectedCategory ? 'primary-btn-style-selected' : 'primary-btn-style'
                            }`}
                            onClick={e => onCategorySelected(null)}
                        >
                            &nbsp;All ({allCount})
                        </Button>
                        {type === 'product' &&
                            productCategories &&
                            productCategories.data &&
                            productCategories.data.map((item, i) => {
                                if (item.id <= 0) return null;
                                return (
                                    <Button
                                        key={i}
                                        style={{ marginBottom: '5px' }}
                                        className={`ml-2 align-middle ${
                                            item.id === selectedCategory ? 'primary-btn-style-selected' : 'primary-btn-style'
                                        }`}
                                        onClick={e => onCategorySelected(item.id)}
                                    >
                                        &nbsp;{item.category} ({item.criteriaCount})
                                    </Button>
                                );
                            })}
                        <Row>
                            {type === 'service' &&
                                serviceCategories &&
                                serviceCategories.data &&
                                serviceCategories.data.map((item, i) => {
                                    if (item.id <= 0) return null;
                                    if (i > 3) return null;
                                    return (
                                        <Button
                                            key={i}
                                            style={{ marginBottom: '5px' }}
                                            className={`ml-2 align-middle ${
                                                item.id === selectedCategory ? 'primary-btn-style-selected' : 'primary-btn-style'
                                            }`}
                                            onClick={e => onCategorySelected(item.id)}
                                        >
                                            &nbsp;{item.category} ({item.criteriaCount})
                                        </Button>
                                    );
                                })}
                        </Row>
                        <Row>
                            {type === 'service' &&
                                serviceCategories &&
                                serviceCategories.data &&
                                serviceCategories.data.map((item, i) => {
                                    if (item.id <= 0) return null;
                                    if (i < 4) return null;
                                    return (
                                        <Button
                                            key={i}
                                            style={{ marginBottom: '5px' }}
                                            className={`ml-2 align-middle ${
                                                item.id === selectedCategory ? 'primary-btn-style-selected' : 'primary-btn-style'
                                            }`}
                                            onClick={e => onCategorySelected(item.id)}
                                        >
                                            &nbsp;{item.category} ({item.criteriaCount})
                                        </Button>
                                    );
                                })}
                        </Row>
                    </div>
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs={12}>
                    <CyderReactTable
                        manual
                        minRows={templates && templates.length === 0 && 1}
                        filterable={false}
                        defaultPageSize={20}
                        className="-highlight mb-2"
                        data={templates}
                        columns={columns}
                        pages={tablePages}
                        totalNum={totalNum}
                        loading={loading}
                        onFetchData={(state, instance) => getDataFunc(state.page, state.pageSize, state.sorted, state.filtered)}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default TemplateListPanel;
