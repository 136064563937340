import StoredLayout from 'layouts/StoredLayout';
import React from 'react';
import { Container } from 'reactstrap';

// SGBC custom components
import PayNowPanel from './components/PayNowPanel';

class MembershipPayNowPage extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        return (
            <Container>
                <PayNowPanel {...this.props} />
            </Container>
        );
    }
}

export default StoredLayout(MembershipPayNowPage);
