import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { Row, Col, Input, Form, FormGroup, Badge } from 'reactstrap';
import { Image } from 'semantic-ui-react';
import Select from 'react-select';
import CyderReactTable from 'cyder/table/CyderReactTable';

import { fetchCertificateList } from '../../../actions/staff/company/staffCompanyDetails';

const statusSelectOptions = [
    {
        value: 'all',
        label: 'All',
    },
    {
        value: 'expired',
        label: 'Expired',
    },
    {
        value: 'active',
        label: 'Valid',
    },
];

const typeOptions = [
    {
        value: 'all',
        label: 'All',
    },
    {
        value: 'product',
        label: 'Product',
    },
    {
        value: 'service',
        label: 'Services',
    },
];

const selectState = {
    step: 0,
    disabled: false,
    searchable: true,
    selectValue: '',
    clearable: true,
};

class CompanyDetailsPageCertificate extends React.Component {
    state = {
        status: {
            value: 'all',
            label: 'All',
        },
        type: {
            value: 'all',
            label: 'All',
        },
        keyword: null,
        page: null,
        disabled: false,
        searchable: true,
        selectValue: '',
        clearable: true,
    };
    componentDidMount() {
        const data = {
            page: 1,
            itemsPerPage: 20,
            keyword: null,
            status: { value: 'all', label: 'All' },
            type: 'product',
        };
        this.props.fetchCertificateList(data);
        // this.getInfo();
    }
    onSelectChange(e) {
        const state = this.state;
        state.status = e;
        this.setState({ state });
        this.getInfo(state.page, state.pageSize, state.keyword, state.status);
    }
    onKeywordChange(e) {
        const state = this.state;
        state.keyword = e.target.value;
        this.setState({ state });
        this.getInfo(state.page, state.pageSize, state.keyword, state.status);
    }
    handleChangeType = type => {
        const state = this.state;
        state.type = type;
        this.setState({ state });
        this.getInfo(state.page, state.pageSize, state.keyword, state.status, type != null ? type.value : null);
    };
    getInfo(page = 0, pageSize = 20, keyword = null, status = null, type) {
        this.props.staffCertListing(page, pageSize, null, null, keyword, status, type);
    }
    render() {
        const columns = [
            {
                Header: 'Previous Cert No',
                sortable: false,
                maxWidth: 120,
                headerStyle: { whiteSpace: 'unset' },
                accessor: 'prevCertNo',
                Cell: ({ original: { previousCertNumber, certType, certId } }) => {
                    return (
                        <Link to={`/staff/certification/certificates/${certType}/certdetails/${certId}`}>
                            {previousCertNumber && previousCertNumber !== null ? previousCertNumber : '-'}
                        </Link>
                    );
                },
            },
            {
                Header: 'Cert No.',
                maxWidth: 120,
                accessor: 'certificateNumber',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },

            {
                Header: 'Description',
                accessor: 'category',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 200,
                Cell: props => {
                    var { certType, serviceCategory, brand, model, productCategory, productSubcategory, productType } = props.original;
                    var productDetail = productCategory + ' > ' + productSubcategory + ' > ' + productType;
                    if (certType === 'service') {
                        return <b>{serviceCategory}</b>;
                    } else {
                        return (
                            <React.Fragment>
                                <b>{productDetail}</b>
                                <div>{brand ? brand.toUpperCase() : ''}</div>
                                <div>{model ? model : ''}</div>
                            </React.Fragment>
                        );
                    }
                },
            },
            {
                Header: 'Rating',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                accessor: 'rating',
                maxWidth: 80,
                Cell: props => {
                    const { certType, serviceRating } = props.original;
                    const isProduct = certType === 'product' ? true : false;

                    if (!props.value) return;
                    if (isProduct) {
                        const array = new Array(parseInt(props.value)).fill(0);
                        return (
                            <Row style={{ marginLeft: '-5px' }}>
                                {array.map((x, i) => {
                                    return <Image key={i} src="/assets/icons/tick.png" alt="tick" width="16" height="12" />;
                                })}
                            </Row>
                        );
                    } else {
                        return parseInt(serviceRating) !== 0 ? 'L' + serviceRating : '-';
                    }
                },
            },
            {
                Header: 'Validity',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                accessor: 'validity',
                maxWidth: 80,
                Cell: props => {
                    const isValid = props.value === 'Valid';
                    return (
                        <Badge
                            size="sm"
                            className="ml-2"
                            style={{
                                background: isValid ? '#6ba17d' : '#858687',
                            }}
                        >
                            {isValid ? props.value : 'Expired'}
                        </Badge>
                    );
                },
            },
            {
                Header: 'Issued',
                style: { whiteSpace: 'unset' },
                maxWidth: 100,
                headerStyle: { whiteSpace: 'unset' },
                accessor: 'issuedOn',
                Cell: props => moment(props.value).format('DD/MM/YYYY'),
            },
            {
                Header: 'Valid Till',
                accessor: 'expiryDate',
                maxWidth: 100,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => moment(props.value).format('DD/MM/YYYY'),
            },
            {
                Header: '',
                sortable: false,
                maxWidth: 50,
                accessor: '',
                Cell: props => {
                    return (
                        <Link to={`/staff/certification/certificates/${props.original.certType}/certdetails/${props.original.certId}`}>
                            <i className="mr-1 material-icons align-text-bottom" style={{ color: 'green' }}>
                                folder_open
                            </i>
                        </Link>
                    );
                },
            },
        ];
        const { type } = this.state;

        return (
            <div className="page-widget">
                {/* <NoShadowPanel borderSize={2} flat titleClass="info"> */}
                <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Col xs={12}>
                        <Form inline>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    id="search-keyword"
                                    placeholder="Keyword"
                                    onChange={e => this.onKeywordChange(e)}
                                />
                                <Select
                                    onChange={e => this.onSelectChange(e)}
                                    value={this.state.status}
                                    className="w-200 ml-2"
                                    placeholder=""
                                    autofocus
                                    options={statusSelectOptions}
                                    id="status-select"
                                    simpleValue
                                    isClearable={true}
                                    name="selected-state"
                                    disabled={selectState.disabled}
                                    searchable={selectState.searchable}
                                />
                                <Select
                                    onChange={e => this.handleChangeType(e)}
                                    value={this.state.type}
                                    className="w-200 ml-2"
                                    placeholder=""
                                    autofocus
                                    options={typeOptions}
                                    id="type-select"
                                    simpleValue
                                    isClearable={true}
                                    name="type"
                                    disabled={selectState.disabled}
                                    searchable={selectState.searchable}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.props.certs}
                            columns={columns}
                            minRows={0}
                            defaultPageSize={20}
                            filterable={false}
                            manual
                            showPagination={true}
                            pages={this.props.tablePages}
                            totalNum={this.props.totalNum}
                            loading={this.props.loading}
                            onFetchData={(state, instance) => {
                                this.props.staffCertListing(
                                    state.page,
                                    state.pageSize,
                                    state.sorted,
                                    state.filtered,
                                    state.keyword,
                                    this.state.status,
                                );
                            }}
                        />
                    </Col>
                </Row>
                {/* </NoShadowPanel> */}
            </div>
        );
    }
}

const mapState = state => ({
    certs: state.staffCertificationApplicationReducer.certs,
    tablePages: state.staffCertificationApplicationReducer.tablePages,
    totalNum: state.staffCertificationApplicationReducer.totalNum,
});

export default connect(mapState, { fetchCertificateList })(CompanyDetailsPageCertificate);
