/**
 * Cyder Login Reducer
 *
 * Author: Fernando
 *
 */
import {
    CYDER_LOGIN_REQUEST,
    CYDER_LOGIN_UPDATE_SESSION,
    CYDER_LOGIN_SUCCESS,
    CYDER_LOGIN_FAILURE,
    CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE,
    CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE,
    CYDER_LOGIN_PAGE_RESET_STATE,
    CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG,
    CYDER_UPDATE_USER_TFA,
} from '../../actions/constants/actionTypes';

const defaultState = {
    failedLoginCount: 0,
    fields: [
        {
            id: 'email',
            value: '',
            name: 'email',
            icon: 'account_circle',
            type: 'text',
            errors: [],
            rules: {
                title: 'Your email',
                required: true,
            },
        },
        {
            id: 'password',
            value: '',
            name: 'password',
            icon: 'lock_outline',
            type: 'password',
            errors: [],
            rules: {
                title: 'Your password',
                required: true,
            },
        },
        {
            id: 'orgid',
            value: '',
            name: 'orgid',
            icon: 'work',
            type: 'text',
            errors: [],
            rules: {
                title: 'Organization ID',
                required: false,
            },
        },
    ],
    loggedIn: false,
    // TODO: find out whether this is used
    userData: {},
    requesting: false,
    loginError: false,
    errorMessage: null,
    refreshTopNav: false,
};

/**
 * Login reducer
 *
 * @param {*} state
 * @param {*} action
 */
export default function cyderLoginReducer(state = defaultState, action) {
    switch (action.type) {
        case CYDER_LOGIN_REQUEST:
            state.requesting = true;
            state.loginError = false;
            return Object.assign({}, state);
        case CYDER_LOGIN_UPDATE_SESSION:
            state.refreshTopNav = action.refreshTopNav;
            return Object.assign({}, state);
        case CYDER_LOGIN_SUCCESS:
            // TODO: save auth token,etc
            state.loggedIn = true;
            state.userData = action.userData;
            state.requesting = false;
            return Object.assign({}, state);
        case CYDER_LOGIN_FAILURE:
            state.failedLoginCount = state.failedLoginCount + 1;
            state.requesting = false;
            state.loginError = true;
            state.errorMessage = 'Error: ' + action.error.errormessage || 'Error';
            return Object.assign({}, state);
        case CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE:
            state.loginError = true;
            state.errorMessage = action.errorMessage;
            return Object.assign({}, state);
        case CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE:
            state.loginError = false;
            state.errorMessage = null;
            return Object.assign({}, state);
        case CYDER_LOGIN_PAGE_RESET_STATE:
            return defaultState;
        case CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG:
            var newState = Object.assign({ loggedIn: false }, state);
            return newState;
        default:
            return state;
    }
}
