import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import history from './history';
import NotificationsSystem from 'reapop';
import theme from './notifications/reapop/reapop-theme-bootstrap';

import { Card, CardBody } from 'reactstrap';
import { Switch, Route } from 'react-router';
// import PrivateRoute from 'cyder/common/PrivateRoute';

import { cyderStaffNavigationCertGetReviewListCount } from 'actions/security/cyderNavigationAction';
import { cyderLogoutAction } from 'actions/security/cyderSecurityAction';

// global css
import 'bootstrap/dist/css/bootstrap.css';
import './css/main.css';
import './scss/main.scss';

// cyder custom
import './css/custom.css';
import './css/sgbcnewtheme.css';
import './css/mobileui.css';
import './css/pages/memberhome.css';

// react-bootstrap table
import 'react-table/react-table.css';
import './css/react-table-custom.css';

// structural elements
import LeftSidebar from './elements/left-sidebar';
import TopNavigation1 from './elements/top-navigation-1';
import TopNavigation2 from './elements/top-navigation-2';
import Jumbotron from './elements/jumbotron';
import Backdrops from './elements/backdrops';
import Navbar2 from './elements/single-top-navbar';
import Footer from './cyder/common/Footer';

// public pages which require non-containered layout
import PublicHome from './pages/public/PublicHome';

// others
import './css/icons/flags.css';
import './css/icons/font-awesome.css';
import './css/icons/ionicons.css';
import './css/icons/material-design-icons.css';
import './css/icons/simple-line-icons.css';
import './css/icons/weather-icons.css';

import './css/ui-elements/alerts.css';
import './css/ui-elements/modals.css';

import './css/ui-elements/badges.css';
import './css/ui-elements/breadcrumbs.css';
import './css/ui-elements/buttons.css';
import './css/ui-elements/images.css';
import './css/ui-elements/lists.css';
import './css/ui-elements/pagination.css';
import './css/ui-elements/progress-bars.css';
import './css/ui-elements/tabs.css';
import './css/ui-elements/typography.css';
import './css/ui-elements/tooltips.css';

import './css/cyder/widget/page-widget.css';
import './css/cyder/fonts/poppins.css';
import './css/cyder/panel/standard-4080-panel.css';
import './css/cyder/form/common-form.css';
import './scss/forms/default-forms.scss';

// routes
import RoutesOther from './RoutesOther';
import RoutesAdmin from './RoutesAdmin';
import RoutesStaff from './RoutesStaff';
import RoutesMember from './RoutesMember';
import RoutesPublic from './RoutesPublic';

// Custom Components
import SGBCNavBar from './cyder/layout/SGBCNavBar';

// DETERMINE USER ROLE FOR ROUTE REDIRECTION
const checkRole = profile => {
    if (!profile || !profile.user || profile.user.is_staff === null) return 'public';
    if (profile.user.is_staff === 0) return 'member';
    if (profile.user.is_staff === 1) {
        if (
            (profile.user.is_superuser && profile.user.is_superuser === 1) ||
            (profile.user.is_staff && profile.user.is_staff === 1) ||
            (profile.user.is_cert_admin && profile.user.is_cert_admin === 1) ||
            (profile.user.is_cert_manager && profile.user.is_cert_manager === 1) ||
            (profile.user.is_event_admin && profile.user.is_event_admin === 1) ||
            (profile.user.is_mailing_list_admin && profile.user.is_mailing_list_admin === 1) ||
            (profile.user.is_membership_admin && profile.user.is_membership_admin === 1)
        )
            return 'admin';
        return 'staff';
    }
};

class App extends Component {
    constructor(props) {
        super(props);
        this.setActiveTab = this.setActiveTab.bind(this);
        this.state = {
            activeTab: '',
        };
    }
    async componentDidMount() {
        this.determineNavMenu(window.location.pathname);
        var currentUserRole = checkRole(this.props.profile);
        var path = window.location.pathname.toLowerCase();
        if (currentUserRole != 'public' && (path == '/' || path.indexOf('/public/') >= 0)) {
            // logout current user trying to access public pages and still have valid login session, for security reason
            await this.props.logout();
            await this.props.setLayout('top-navigation-2');
        } else {
            if (currentUserRole == 'staff' || currentUserRole == 'admin') {
                await this.props.getCertificationMyReviewCount();
            }
        }
    }
    determineNavMenu(pathname) {
        const endPath = pathname.split('/').pop();
        this.setState({
            activeTab: endPath,
        });
    }
    setActiveTab(tabName) {
        this.setState({ activeTab: tabName });
    }
    render() {
        const { layout, background, navbar, topNavigation, logo, breadcrumb, leftSidebar, collapsed } = this.props.config;

        // Added by Fernando on 2 Sep 2018 to force the theme based on URL
        var updatedLayout = '';
        var path = window.location.pathname.toLowerCase();
        if (path.indexOf('/staff/') >= 0) {
            updatedLayout = 'default-sidebar-1';
        } else {
            updatedLayout = 'top-navigation-2';
        }
        var updatedNavBar = 'light';
        // ---------------------------------------------------------------

        var userRole = checkRole(this.props.profile);
        const isEmptyView = updatedLayout === 'empty-view-1';
        const isEmptyView2 = updatedLayout === 'empty-view-2';
        const defaultSideBarLayout = updatedLayout === 'default-sidebar-1';
        const topNav = updatedLayout === 'top-navigation-1' || updatedLayout === 'top-navigation-2';

        return (
            <ConnectedRouter history={history}>
                <React.Fragment>
                    <div
                        data-logo={logo}
                        data-layout={layout}
                        data-collapsed={collapsed}
                        data-navbar={updatedNavBar}
                        data-background={background}
                        data-left-sidebar={leftSidebar}
                        data-top-navigation={topNavigation}
                        className={isEmptyView || isEmptyView2 ? 'cf-height-calc' : 'containerFlex'}
                    >
                        <NotificationsSystem theme={theme} />
                        <Backdrops />
                        <SGBCNavBar />
                        <Navbar2 />
                        <TopNavigation1 />
                        <TopNavigation2
                            activeTab={this.state.activeTab}
                            setActiveTab={this.setActiveTab}
                            isMember={path.indexOf('/member/') >= 0}
                        />
                        <Switch>
                            <Route exact path="/" component={PublicHome} />
                            {userRole == 'admin' && (
                                <Route
                                    path="/admin"
                                    render={props => (
                                        <RoutesRest
                                            role={'admin'}
                                            topNav={topNav}
                                            breadcrumb={breadcrumb}
                                            isEmptyView={isEmptyView}
                                            isEmptyView2={isEmptyView2}
                                            jumbotronText={this.props.jumbotronText}
                                            defaultSideBarLayout={defaultSideBarLayout}
                                            {...props}
                                        />
                                    )}
                                />
                            )}

                            {(userRole == 'admin' || userRole == 'staff') && (
                                <Route
                                    path="/staff"
                                    render={props => (
                                        <RoutesRest
                                            role={'staff'}
                                            topNav={topNav}
                                            breadcrumb={breadcrumb}
                                            isEmptyView={isEmptyView}
                                            isEmptyView2={isEmptyView2}
                                            jumbotronText={this.props.jumbotronText}
                                            defaultSideBarLayout={defaultSideBarLayout}
                                            mainStyle={{
                                                background: '#f3f3f4',
                                            }}
                                            {...props}
                                        />
                                    )}
                                />
                            )}
                            {userRole == 'member' && (
                                <Route
                                    path="/member"
                                    render={props => (
                                        <RoutesRest
                                            role={'member'}
                                            topNav={topNav}
                                            breadcrumb={breadcrumb}
                                            isEmptyView={isEmptyView}
                                            isEmptyView2={isEmptyView2}
                                            jumbotronText={this.props.jumbotronText}
                                            defaultSideBarLayout={defaultSideBarLayout}
                                            mainStyle={{}}
                                            {...props}
                                        />
                                    )}
                                />
                            )}
                            <Route
                                path="/public"
                                render={props => (
                                    <RoutesRest
                                        role={'public'}
                                        topNav={topNav}
                                        breadcrumb={breadcrumb}
                                        isEmptyView={isEmptyView}
                                        isEmptyView2={isEmptyView2}
                                        jumbotronText={this.props.jumbotronText}
                                        defaultSideBarLayout={defaultSideBarLayout}
                                        mainStyle={{
                                            paddingRight: '0px',
                                            paddingLeft: '0px',
                                        }}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path="/"
                                render={props => (
                                    <RoutesRest
                                        role={'other'}
                                        topNav={topNav}
                                        breadcrumb={breadcrumb}
                                        isEmptyView={isEmptyView}
                                        isEmptyView2={isEmptyView2}
                                        jumbotronText={this.props.jumbotronText}
                                        defaultSideBarLayout={defaultSideBarLayout}
                                        mainStyle={{}}
                                        {...props}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                    {(userRole == 'public' || userRole == 'member') && <Footer />}
                </React.Fragment>
            </ConnectedRouter>
        );
    }
}
const RoutesRest = ({ isEmptyView, isEmptyView2, topNav, breadcrumb, jumbotronText, defaultSideBarLayout, role, mainStyle }) => (
    <div
        className={
            isEmptyView || isEmptyView2
                ? 'mainContent d-flex flex-column justify-content-start'
                : topNav
                ? 'container-fluid mainContent'
                : 'mainContent'
        }
        style={{
            padding: '0px',
        }}
    >
        <div
            style={isEmptyView2 || role == 'admin' || role == 'staff' ? { height: '100%' } : null}
            className={isEmptyView ? 'd-flex justify-content-center' : isEmptyView2 ? 'mainContentChild row' : 'row'}
        >
            <LeftSidebar />
            <div className={isEmptyView2 ? 'col-sm-12 col-md-6 col-lg-7 col-xl-7' : 'd-none'} />
            <div className={isEmptyView ? 'main' : isEmptyView2 ? 'col-sm-12 col-md-6 col-lg-5 col-xl-5' : 'col main'} style={mainStyle}>
                {breadcrumb && <Jumbotron jumbotronText={jumbotronText} />}
                {isEmptyView || isEmptyView2 || defaultSideBarLayout ? (
                    <Card className="semi-transparent login-card" style={{ height: '100%' }}>
                        <CardBody>
                            {role === 'public' && <RoutesPublic />}
                            {role === 'member' && <RoutesMember />}
                            {role === 'staff' && <RoutesStaff />}
                            {role === 'admin' && <RoutesAdmin />}
                            {role === 'other' && <RoutesOther />}
                        </CardBody>
                    </Card>
                ) : topNav ? (
                    <div
                        style={
                            role === 'public'
                                ? {
                                      marginLeft: '0px',
                                      width: '100%',
                                      marginTop: '0px',
                                      marginRight: '0px',
                                  }
                                : null
                        }
                    >
                        {role === 'public' && <RoutesPublic />}
                        {role === 'member' && <RoutesMember />}
                        {role === 'staff' && <RoutesStaff />}
                        {role === 'admin' && (
                            <div
                                style={{
                                    padding: '18px',
                                    background: 'rgb(243, 243, 244)',
                                }}
                            >
                                <div className="page-widget" style={{ paddingBottom: '20px' }}>
                                    <RoutesAdmin />
                                </div>
                            </div>
                        )}
                        {role === 'other' && <RoutesOther />}
                    </div>
                ) : (
                    <RoutesOther />
                )}
            </div>
        </div>
    </div>
);

const mapStateToProps = (state, ownProps) => {
    return {
        config: state.config,
        nav: state.navigationStaff,
        jumbotronText: state.pagedata.jumbotronText,
        profile: state.profile,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCertificationMyReviewCount: () => {
            return dispatch(cyderStaffNavigationCertGetReviewListCount());
        },
        logout: () => {
            return dispatch(cyderLogoutAction());
        },
        setLayout: layout => {
            dispatch({
                type: 'SET_CONFIG',
                config: {
                    layout: layout,
                },
            });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
