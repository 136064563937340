import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

import { Row, Col, Button } from 'reactstrap';

const CompanyDetailsPageMembershipPanel = ({
    memberApplication,
    selectedMembershipTier,
    membershipTierOnChange,
    updateMembershipTier,
    printCert,
    memberInfo,
    viewMembershipTier,
    resubmitMembershipTier,
    regenerateCertificate,
    loadingGenerateCert,
}) => {
    return (
        <div className="page-widget">
            <div className="row">
                <div className="col-md-12">
                    {memberInfo.dateJoined && memberInfo.dateJoined !== 0 ? (
                        <React.Fragment>
                            <Button className="primary-btn-style mr-2 text-bold" hidden={memberApplication.id <= 0} onClick={printCert}>
                                <i className="fa fa-certificate" /> View Certificate
                            </Button>
                            {loadingGenerateCert ? (
                                <LoadingSpinner />
                            ) : (
                                <Button className="primary-btn-style mr-2" onClick={regenerateCertificate} id="regenerateMemberCertKey">
                                    <i className="fa fa-save" />
                                    &nbsp; Regenerate Certificate
                                </Button>
                            )}
                            <hr />
                        </React.Fragment>
                    ) : null}

                    {memberInfo.dateJoined && memberInfo.dateJoined !== 0 ? (
                        <Col>
                            {memberApplication.length > 0 &&
                                (memberApplication[0].tierUpgraded == 1 || memberApplication[0].tierUpgraded == 2) && (
                                    <React.Fragment>
                                        {memberApplication[0].tierUpgraded == 1 && (
                                            <Row>Membership Tier Advancement responses updated. Assess documents before awarding Tier.</Row>
                                        )}
                                        {memberApplication[0].tierUpgraded == 2 && (
                                            <Row>Membership tier upgrade document has been returned</Row>
                                        )}
                                        <Row>
                                            <Button
                                                className="primary-btn-style mr-2"
                                                onClick={e => viewMembershipTier(memberApplication[0].id)}
                                            >
                                                <i className="fa fa-save" />
                                                &nbsp; View Documents
                                            </Button>
                                        </Row>
                                    </React.Fragment>
                                )}
                        </Col>
                    ) : null}

                    {memberApplication.length > 0 && (memberApplication[0].tierUpgraded == 1 || memberApplication[0].tierUpgraded == 2) && (
                        <hr />
                    )}
                    <div className="ibox-content">
                        {memberApplication.length > 0 && (
                            <Row>
                                <Col xs={1}>
                                    <small>Ref. No.</small>
                                </Col>
                                <Col xs={2}>
                                    <small>Submitted On</small>
                                </Col>
                                <Col xs={4}>
                                    <small>Status</small>
                                </Col>
                            </Row>
                        )}

                        {memberApplication.length > 0 &&
                            memberApplication.map((element, id) => {
                                return (
                                    <Row key={id}>
                                        <Col xs={1}>
                                            <div>
                                                <strong>
                                                    <Link to={`/staff/membership/applications/${element.id}`}>{element.id}</Link>
                                                </strong>
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div>
                                                <strong>
                                                    <Link to={`/staff/membership/applications/${element.id}`}>
                                                        {moment(element.applicationFirstSubmittedTimestamp).format('D MMM YYYY')}
                                                    </Link>
                                                </strong>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div>
                                                <strong>
                                                    <Link to={`/staff/membership/applications/${element.id}`}>
                                                        Application {element.status}
                                                    </Link>
                                                </strong>
                                            </div>
                                        </Col>
                                    </Row>
                                );
                            })}

                        {memberApplication.length <= 0 && <i>No membership application</i>}
                    </div>

                    {/* <Button color="danger" id="regenerateMemberCertKey">
                        Regenerate Membership Certificate
                    </Button>
                    <Button
                        color="success"
                        hidden={memberApplication.id <= 0}
                        className="btn-outline mr-1 text-bold"
                        onClick={() => alert('Button not link')}
                    >
                        <i className="fa fa-file-text" /> View Membership Letter
                    </Button>
                    <Button color="danger" id="regenerateMemberLetterKey">
                        Regenerate Membership Letter
                    </Button>

                    <Button color="success" className="btn-outline mr-1">
                        <i className="fa fa-print" /> Print C4
                    </Button>
                    <Button color="success" className="btn-outline">
                        <i className="fa fa-print" /> Print DL
                    </Button> */}
                </div>
            </div>
            <br />
        </div>
    );
};

export default CompanyDetailsPageMembershipPanel;
