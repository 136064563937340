import {
    STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_REQUEST,
    STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_SUCCESS,
    STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_FAILURE,
    STAFF_DIRECTORY_LOGGING_RESET_TO_DEFAULT,
} from '../../constants/actionTypesStaffCompanyDetails';
import withQuery from 'with-query';
import fetchlib from '../../../js/fetchlib';
import { SGBC_API_CORS_ROOT } from '../../../config';

export function staffCompanyDetailsLoggingGetLoggingActivities(companyId, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'getAllLogByCompanyId',
        });
        const body = {
            companyId: parseInt(companyId),
            sorted,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_REQUEST,
            STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_SUCCESS,
            STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_FAILURE,
        );
    };
}
export function staffCompanyDetailsLoggingResetToDefault() {
    return dispatch => {
        dispatch({
            type: STAFF_DIRECTORY_LOGGING_RESET_TO_DEFAULT,
        });
        return Promise.resolve();
    };
}
